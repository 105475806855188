<div class="col-sm-12 row p-2 m-0 whitebg">
  <div class="col-sm-2 mp0">
    <div class="row">
      <div class="col-sm-12">
        <mat-accordion>
          <mat-expansion-panel
            expanded
            *ngFor="let filter of filterattrData; let index = index"
            class="m-0 filterpanel"
            expanded
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="font-14">
                {{ filter.attr_grp_name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ul
              *ngFor="
                let arr of filter.attribute_details;
                let attrindex = index
              "
              class="font-13 check-Price p-0"
            >
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="arr.attribute"
                (change)="
                  changeFilter(
                    $event,
                    'attr',
                    arr.attribute,
                    attrindex,
                    arr.attribute_id
                  )
                "
              >
                <li>{{ arr.attr_name }}</li>
              </mat-checkbox>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel
            class="m-0 filterpanel"
            expanded
            *ngIf="brandsData.length > 0"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="font-14"> Brand </mat-panel-title>
            </mat-expansion-panel-header>
            <ul
              *ngFor="let brand of brandsData; let bidx = index"
              class="font-13 check-Price p-0"
            >
              <mat-checkbox
                class="example-margin"
                [(ngModel)]="brand.namee"
                (change)="
                  changeFilter(
                    $event,
                    'brand',
                    brand.name,
                    bidx,
                    brand.manufacturer_id
                  )
                "
              >
                <li>{{ brand.name }}</li>
              </mat-checkbox>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion>
          <mat-expansion-panel
            expanded
            class="filterpanel"
            *ngIf="filterpricelist.length > 0"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="font-14"> Price </mat-panel-title>
            </mat-expansion-panel-header>
            <ul
              *ngFor="let p of filterpricelist; let pindex = index"
              class="font-13 check-Price p-0"
            >
              <mat-checkbox
                class="example-margin w100"
                (change)="changeFilter($event, 'price', p, pindex, p)"
                value="{{ p }}"
              >
                <li>{{ p }}</li>
              </mat-checkbox>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
        <!--    <mat-accordion>
                    <mat-expansion-panel expanded class="filterpanel" *ngIf="filterpricelist.length > 0">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="font-14">
                                Price
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <span *ngFor="let p of filterattrData;let pindex=index" class="font-13">
                            <mat-checkbox class="example-margin w100"
                                (change)="changeFilter($event,'price',p,pindex,p)" value={{p}}>{{p}}</mat-checkbox>
                        </span>
                    </mat-expansion-panel>
                </mat-accordion> -->
      </div>
    </div>
    <!-- <p class="addsTitle"><marquee>Discounts available on below products</marquee></p> -->
    <!-- <div class="card p-0 grid-product-card d-flex align-items-stretch" >
            <div class="card-body pb-0 pt-2 pr-2 pl-2 ">
                <img class="addsImgwidth" src="{{imgurl}}add5.jpg" alt="" >
            </div>
            <div class="adsDescription">Flat 20% off</div>
        </div>  
      
        <div class="card p-0 grid-product-card d-flex align-items-stretch afterFirstDiv" >
            <div class="card-body pb-0 pt-2 pr-2 pl-2 ">
                <img class="addsImgwidth" src="{{imgurl}}add1.jpg" alt="" >
            </div>
            <div class="adsDescription">Flat 35% off</div>
        </div>   -->
  </div>

  <div class="col-sm-10">
    <div
      class="row searchResultCard text-center"
      *ngIf="this.summaryData.productDetails.length === 0"
    >
      <div class="col-sm-8 offset-sm-2 mt-3">
        <img
          src="{{ imgurl }}noImag.png"
          alt=""
          class="img-fluid d-block pl-2 pr-0 cartEmptyText"
        />
        <h6 class="mt-3">No items found</h6>
      </div>
    </div>
    <!-- <div
      class="row searchResultCard"
      *ngIf="this.summaryData.productDetails.length > 0"
    >
      <div
        class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3 p-0 ml-3 mr-1"
        *ngFor="let p of this.summaryData.productDetails; let i = index"
      >
        <div class="card p-0 grid-product-card d-flex align-items-stretch">
          <div class="card-body proCard">
            <div class="row mp0les">
              <div class="col-10">
                <span *ngIf="p?.discountPrice > 0">
                  <img src="assets/loginImgs/offerImg.png" class="off-img" />
                  <span class="off-text3">
                    <p>Save</p>
                    <p
                      [ngClass]="{ disPrice1: p.discountPrice.length > 5 }"
                      *ngIf="p?.priceType === 'F'"
                    >
                      {{ p?.currencyIndicator }}{{ p?.discountPrice }}
                    </p>
                    <p
                      [ngClass]="{ disPrice1: p.discountPrice.length > 5 }"
                      *ngIf="p?.priceType === 'P'"
                    >
                      {{ p?.discountPrice }}{{ p?.symbolLeft }}
                    </p>
                   
                  </span>
                </span>

         
              </div>
              <div class="col-2 wishBtn">
                <p
                  class="addCart"
                  *ngIf="p.wishlistflag === 1"
                  [matMenuTriggerFor]="afterMenu"
                >
                  <i class="fa fa-heart" title="addtocart"></i>
                </p>
                <p
                  class="addCart"
                  *ngIf="p.wishlistflag === 0"
                  (click)="removeWishlist(i, p.wishlistStatusId)"
                >
                  <i
                    class="fas fa-heart whishListAddedIcon"
                    title="addtocart"
                  ></i>
                </p>
              </div>
            </div>
            <div
              class="col-sm-12 prodImage"
              (click)="goToProductsView(p.productId)"
            >
            
              <img [src]="p.image" class="product-image" />
             
            </div>
            <p
              class="productName"
              title="{{ p?.name }}"
              (click)="goToProductsView(p.productId)"
            >
              {{ p?.name }}
            </p>
          
            <div class="col-sm-12 p-0">
              <p class="priceTag">
                <span
                  class="special-price"
                  *ngIf="
                    p.discountPrice !== 0 &&
                    p.discountPrice !== undefined &&
                    p.discountPrice !== null
                  "
                  >{{ p?.currencyIndicator }}&nbsp;{{ p?.offPrice }}</span
                >
                <span
                  class="special-price"
                  *ngIf="
                    p.discountPrice === 0 ||
                    p.discountPrice === undefined ||
                    p.discountPrice === null
                  "
                  >{{ p?.currencyIndicator }}&nbsp;{{ p?.price }}</span
                >

                <span
                  class="regular-price"
                  *ngIf="
                    p.discountPrice !== 0 &&
                    p.discountPrice !== undefined &&
                    p.discountPrice !== null
                  "
                >
                  {{ p?.currencyIndicator }}&nbsp;{{ p?.price }}</span
                >

              </p>
            </div>
            <div class="row pmo">
              <div class="col-5 pmo">
                <ngb-rating
                  class="font-20 ngbRatingColor"
                  [max]="5"
                  [(rate)]="p.averageRating"
                  [starTemplate]="t"
                  [readonly]="true"
                  >{{ p?.averageRating }}
                </ngb-rating>
              </div>
              <div class="col-6 pmo">
               
                <button
                  class="btn btn-outline-info btn-sm"
                  (click)="cartClk(p, price)"
                >
                  ADD TO CART
                </button>
              </div>

       
            </div>
           
            <mat-menu #afterMenu="matMenu" xPosition="before">
              <div
                mat-menu-item
                [disableRipple]="true"
                (click)="$event.stopPropagation()"
                class="saveMenuItem wishlistform"
              >
                <div class="row p-0">
                  <div class="col-12 pt-2" *ngIf="foldervisible">
                    <div class="col-12">
                      <mat-form-field appearance="outline" class="font-10">
                        <mat-label>Folder Name</mat-label>
                        <input
                          matInput
                          placeholder="Enter Folder Name"
                          [(ngModel)]="folderName"
                          name="foldername"
                        />
                      </mat-form-field>
                    </div>

                    <div class="col-12 row m-0">
                      <div class="col-6 p-0">
                        <p class="newfolder text-center" (click)="hidefolder()">
                          Back
                        </p>
                      </div>
                      <div class="col-6 pr-3" (click)="addFolder()">
                        <p class="newfolder text-center">Add</p>
                      </div>
                
                    </div>
                  </div>
                  <div class="col-sm-12 pt-2" *ngIf="formhide">
                    <form>
                      <div class="col-sm-12">
                        <mat-form-field class="font-10" appearance="outline">
                          <mat-label>Folder</mat-label>
                          <mat-select [(ngModel)]="folderList" name="folders">
                            <mat-option
                              *ngFor="let a of folders"
                              [value]="a.folder_id"
                            >
                              {{ a.folder_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <div class="col-12 row m-0">
                        <div class="col-6 p-0" (click)="showfolder()">
                          <p class="newfolder text-center">New Folder</p>
                        </div>
                        <div class="col-6 pr-3" (click)="addtoWishlist(p, i)">
                          <p class="newfolder text-center">Add</p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        
      </div>
    </div> -->

    <div class="row" *ngIf="this.summaryData.productDetails.length > 0">
      <div class="d-flex">
        <div
          class="item-card mt-5 ml-5 p-0"
          *ngFor="let p of this.summaryData.productDetails; let i = index"
        >
          <span class="text-right">
            <span *ngIf="p.wishlistflag === 1" [matMenuTriggerFor]="afterMenu">
              <!-- <i class="fa fa-heart heartIco product-Wishlist"></i> -->
              <img
                src="assets/svg/Wishlist.svg"
                alt="Wishlist"
                width="25px"
                height="25px"
                class="product-Wishlist"
              />
            </span>
            <mat-menu #afterMenu="matMenu" xPosition="before">
              <div
                mat-menu-item
                [disableRipple]="true"
                (click)="$event.stopPropagation()"
                class="saveMenuItem wishlistform"
              >
                <div class="row p-0">
                  <div class="col-12 pt-2" *ngIf="foldervisible">
                    <div class="col-12">
                      <mat-form-field appearance="outline" class="font-10">
                        <mat-label>Folder Name</mat-label>
                        <input
                          matInput
                          placeholder="Enter Name"
                          [(ngModel)]="folderName"
                          name="foldername"
                        />
                      </mat-form-field>
                    </div>
                    <div class="col-12 row m-0">
                      <div class="col-6 p-0">
                        <p class="newfolder text-center" (click)="hidefolder()">
                          Back
                        </p>
                      </div>
                      <div class="col-6 pr-3" (click)="addFolder()">
                        <p class="newfolder text-center">Add</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 pt-2" *ngIf="formhide">
                    <form>
                      <div class="col-sm-12">
                        <mat-form-field class="font-10" appearance="outline">
                          <mat-label>Folder</mat-label>
                          <mat-select [(ngModel)]="folderList" name="folders">
                            <mat-option
                              *ngFor="let a of folders"
                              [value]="a.folder_id"
                            >
                              {{ a.folder_name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12 row m-0">
                        <div class="col-6 p-0" (click)="showfolder()">
                          <p class="newfolder text-center">New Folder</p>
                        </div>
                        <div class="col-6 pr-3" (click)="addtoWishlist(p, i)">
                          <p class="newfolder text-center">Add</p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </mat-menu>

            <span *ngIf="p.wishlistflag === 0">
              <!-- <i
                class="fa fa-heart whishListAddedIcon product-Wishlist"
                (click)="removeWishlist(i, p.wishlistStatusId)"
              ></i> -->

              <img
                src="assets/svg/btn-whishlist-selected.svg"
                alt="Wishlist"
                width="25px"
                height="25px"
                class="product-Wishlist"
                (click)="removeWishlist(i, p.wishlistStatusId)"
              />
            </span>
          </span>

          <div
            class="product-img d-flex justify-content-center"
            (click)="goToProductsView(p.productId)"
          >
            <img [src]="p.image" alt="watch-icon" height="200px" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <span class="product-offer" *ngIf="p?.discountPrice > 0">
              <span>Save</span>
              <span
                [ngClass]="{ disPrice1: p.discountPrice.length > 5 }"
                *ngIf="p.priceType === 'F'"
              >
                {{ p.currencyIndicator }}{{ p.discountPrice | slice : 0 : 7 }}
              </span>
              <span
                [ngClass]="{ disPrice1: p.discountPrice.length > 5 }"
                *ngIf="p.priceType === 'P'"
              >
                {{ p.discountPrice | slice : 0 : 7 }}{{ p.symbolLeft }}
              </span>
            </span>
            <span class="d-flex align-items-center star-rating">
              &nbsp; {{ p.averageRating }}&nbsp;
              <img src="assets/svg/star.svg" alt="star-logo" />&nbsp; | &nbsp;
              5&nbsp;&nbsp;
            </span>
          </div>

          <div class="d-flex flex-column product-content">
            <div
              class="d-flex align-items-center justify-content-between pb-3"
              (click)="goToProductsView(p.productId)"
            >
              <!-- <span class="product-name" title="{{ p.name }}">{{
                p.name
              }}</span> -->
              <span
                class="product-name"
                title="{{ p.name }}"
                [innerHTML]="cardtruncateHTML(p.name)"
              ></span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="d-flex align-items-center">
                <span
                  class="product-currency"
                  *ngIf="
                    p.discountPrice !== 0 &&
                    p.discountPrice !== undefined &&
                    p.discountPrice !== null
                  "
                >
                  {{ p.currencyIndicator }}{{ p.offPrice }}
                </span>
                <span
                  class="product-currency"
                  *ngIf="
                    p.discountPrice === 0 ||
                    p.discountPrice === undefined ||
                    p.discountPrice === null
                  "
                >
                  {{ p.currencyIndicator }}{{ p.price }}
                </span>
                <span
                  class="OfferPricewek1 ml-1"
                  *ngIf="
                    p.discountPrice !== 0 &&
                    p.discountPrice !== undefined &&
                    p.discountPrice !== null
                  "
                >
                  {{ p.currencyIndicator }}{{ p.price }}
                </span>
              </span>

              <button class="add-cart" (click)="cartClk(p, price)">
                ADD TO CART
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
