import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PlaceOrderService } from './services/place-order.service';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../services/shared.service';
import { PlaceOrderObject, CartDataForplaceOrder, CartDetails, GetDeliveryAddrDetails } from './model/place-order-model';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { CommonService } from '../../helper/common.service';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { deepStrictEqual } from 'assert';
import { environment } from 'src/environments/environment.prod';
import { MenuService } from 'src/app/layout/service/menu.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatVerticalStepper } from '@angular/material/stepper';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/helper/auth.service';
import { AppModule } from 'src/app/app.module';
import { OrderData } from '../myorders/modal/myorder-modal';
import { data } from 'jquery';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss']
})
export class PlaceOrderComponent implements OnInit {
  addressDetails: any;
  expressCharge: any;
  standardCharge: any;
  constructor(private ps: PlaceOrderService, private cookieService: CookieService, public dialog: MatDialog, private common: CommonService,
    private shared: SharedService, private http: HttpClient, private snack: MatSnackBar,
    private menu: MenuService, private router: Router, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, public formBuilder: FormBuilder, public auth: AuthService) {
    this.imgurl = environment.imageURL;
  }
  @ViewChild(MatVerticalStepper) stepper: MatVerticalStepper;
  @ViewChild('addressDiv', { read: ElementRef }) public addressDiv: ElementRef<any>;

  imgurl;
  addString: number;
  paymentMethods: any[] = [];
  paymentOption: 3;
  isLinear = false;
  unitId = '711163107';
  orderDetails: any;

  deladdress: any;
  myCart: any;
  vocherFlag;
  disabled = false;
  disabledp = false;

  cartData = [];
 
  totalPrice: number;
  totaldummyPrice: number;

  paymentIDForPay: any;
  selectedState: any = null;
  productMainImage: any;
  selectedAddressForDelivery;
  estimatedDeliveryDtls;
  deliveryCharges;
  orderedProductEntireDetails: any;
  selectedDeliveryMode;
  deliveryMode;
  taxDetails;
  detailsForConfirmOrder;
  countryInfo;
  logedSeeionId;
  logedCustomerId;
  logedEmailId;
  logedUserName;
  regionInfo;
  newaddressform;
  resultOfDeliveryModeSuccess;
  noAddressFoundFlag;
  // ---------new----
  shippAdd = true;
  newAddForm = false;
  ordersummChangeDiv = false;
  orderexpandDiv = false;
  orderConfirmFlag = false
  paymentFlag = false;
  proceedFlag = false;
  inactiveSummary = false;
  addressShipView = false
  // --------------
  shippD;

  paymentexpandDiv;
  resultOfAddressInsertSuccess;
  logedPhone;
  signupForm: FormGroup;
  guestSessionId;
  summaryData;
  totoal;
  voucherArray: any[];

  safeDiv = false;
  AddNAddrk = true;
  changeaddClk = false;
  aa = true;
  does = false;
  lenth16more = false;
  cvverror = false;
  options;
  confrmOrderFlag = false;
  amountcurrency;
  floatNuumber;
  raziid;
  selectedDelivery = [];

  stripeFlag = false;
  paypalFlag = false;
  razorFlag = false;
  codFlag = false

  states: any[] = [
    { name: 'Arizona', code: 'Arizona' },
    { name: 'California', value: 'California' },
    { name: 'Florida', code: 'Florida' },
    { name: 'Ohio', code: 'Ohio' },
    { name: 'Washington', code: 'Washington' }
  ];

  cities1: any[] = [];

  cities2: any[] = [];

  city1: any = null;

  city2: any = null;
  dialogRef;
  dialogNewAddr;
  deliverMode;
  getDeliveryCharges: number;
  numberstring;
  rzp1;
  amountgettingStr;
  orderId;
  currencyIndicator;
  Url: string;
  razorpayOrderId;
  deliveryOptionList = [];
  cId;
  pId;
  deliveryTypeList = [];
  getCoupRes = [];
  currencyId;
  storeId;
  eventTriggered = false;
  couponFlag
  //models initializing
  VocherForm: FormGroup;
  vocherEntercodeArray = [];
  tenantId;
  panelOpenState = false;
  imageParse;
 

  butDisabled = true;

  placeOrdObj: PlaceOrderObject = new PlaceOrderObject();
  expressFlag;
  selectedDeliveryFlag ;
  btnDisabled ;
  buyNowFlag = false
  currencyCode
  optionTypeL = [11,12];
  addressFlag = false;
  optionTypeList2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]
  ngOnInit(): void {
  
    console.log('optionTypeL',this.optionTypeList2);
    debugger
    this.disabled = true;
    this.btnDisabled = true;
    this.vocherFlag = false;
    this.shippD = false;
   
    this.paymentexpandDiv = false;
   
    
    this.disabledp = true;

    console.log("oninit expressFlag", this.expressFlag)
    // this.safeDiv = false; ts..coupArray,,, couponRes
    this.activatedRoute.queryParams.subscribe(params => {
      const url = window.location.href;
      this.couponFlag = params.couponFlag;
      
      if(url.includes('couponRes')){
        const coupArr = decodeURI(params.couponRes);
        const testCoup = JSON.parse(coupArr)
        this.getCoupRes = testCoup;
      }
      if(url.includes('buynowData')){
        this.buyNowFlag =  JSON.parse(params.buyNowFlag);
        const buyArr = decodeURI(params.buynowData);
       const data= JSON.parse(buyArr)
       this.cartData.push(data)
      }
      console.log('copresArray', this.getCoupRes);
      console.log('couponFlag', this.couponFlag);
    })
    this.VocherForm = this.formBuilder.group({
      VocherCode: ['', Validators.required],
    }),
      this.logedPhone = sessionStorage.getItem('telephone');
    this.logedSeeionId = sessionStorage.getItem('sessionId');
    debugger
    this.logedCustomerId = sessionStorage.getItem('customerId');
    this.logedEmailId = sessionStorage.getItem('userEmail');
    this.logedUserName = sessionStorage.getItem('userdata');
    this.logedPhone = sessionStorage.getItem('telephone');
    this.storeId = sessionStorage.getItem('StoreId');
    this.currencyId = sessionStorage.getItem('currencyId');
    this.tenantId = sessionStorage.getItem('tenantId');
    this.getOrderSummary();
    this.viewCart();
    this.handleGetCountries();
    this.getPaymentMethods();
    this.getDeliverAddressDetails();
    // this.rClick();
    
    this.newaddressform = {
      firstname: '', lastname: '', default_address: false,
      pincode: '', address: '', city: '', country: '', zone: '', addresstype: ''
    }

    this.signupForm = this.formBuilder.group({
      deliverMode: ['', Validators.required]
    })

    this.common.aClickedEvent
      .subscribe((data: any) => {
        console.log('Event message from Component A: ' + data);
        this.storeId = sessionStorage.getItem('StoreId')
        this.viewCart();
      });
    this.common.currencyClickedEvent
      .subscribe((data: any) => {
        console.log('triggered flag', this.eventTriggered)
        console.log('Event message from Component A currency: ' + data);
        this.currencyId = sessionStorage.getItem('currencyId')
        this.viewCart();
        this.getOrderProductSummary('Standard');
      });
  }

  onTabOpen(event) {
    //console.log(event);
    if (event.index === 1) {
      this.getOrderSummary();
    }
  }
  onTabClose(event) { }

 /**
   * @remarks view cart product list
   * @AddedBy  Amar
   * @ModifiedBy Devi added store validations & changed the payloads
   * @version 1.0
  */
  viewCart(): any {
    // Function call eventFlag=null
    let body;
    this.spinner.show();
    body = {
      preview_op_type: "view_cart", user_name: this.logedEmailId, tenant_id: this.tenantId,
      login: true, session_id: this.logedSeeionId, 
      // shipping_country_id: 223, shipping_zone_id: 3613,
       voucher_applied: false
    };
    body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), [this.getCoupRes])
    body = this.common.referenceIdValidation(body)
    const success = this.ViewCartSuccess.bind(this);
    const error = this.onError.bind(this);
    this.common.http.post('Preview', body, success, error);
  }
 
  ViewCartSuccess(data): any {
    console.log('viewcart', data);
    if (data.res_status === false) {
      this.spinner.hide();
      if(data.Data.cart_details.length === 0 ){
        this.router.navigate(['/cart'])
      }
    }
    else {
      this.spinner.hide();
      this.placeOrdObj.placeOrderData = Array<CartDataForplaceOrder>();
      const dataset = new CartDataForplaceOrder();
      dataset.Grand_Total = data.Data.price;
      dataset.cart_count = data.Data.cart_count;
      dataset.lines = data.Data.cart_details;
      this.expressCharge = data.Data.express_charges
      this.standardCharge = data.Data.standard_charges  
      this.currencyIndicator = data.Data.currency_indicator
      for (let a of dataset.lines) {
        this.imageParse = JSON.parse(a.image);
        this.totoal = dataset.lines[0].price;
        const body = { image: this.imageParse.file_data[0].media_data, data: a }
        if( this.buyNowFlag === false){
          this.cartData =  data.Data.cart_details;
        }
        
      }
      console.log('view cart cart data', this.cartData)
      this.placeOrdObj.placeOrderData.push(dataset);
    }
  }
  onError(data) {
  }
  getOrderSummary() {
    this.ps.getSampleProducts().subscribe((resp) => {
      resp.ecomData.map((item) => {
        if (item._unit_id === this.unitId) {
          this.orderDetails = item;
          this.orderDetails.quantity = 1;
        }
      });
    });
  }
  addLineLevelDeliveryDates(event, type) {
    if (type === 'add') {
      this.orderDetails.quantity = this.orderDetails.quantity + 1;
    } else if (type === 'sub') {
      this.orderDetails.quantity = this.orderDetails.quantity - 1;
    }
  }

  addAddress(openAddressPopup) {
    this.dialogNewAddr = this.dialog.open(openAddressPopup, { width: '45%', height: '428px', disableClose: true });
  }
  closeNewAddressDialog() {
    this.dialogNewAddr.close();
  }
  closepaymentDialog() {
    this.dialogNewAddr.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }
  placeOrder() {
    if (this.paymentOption !== undefined) {

    }
  }


  handlePayment() {
    const body = {
      payer: {
        payment_method: "paypal"
      },
      transactions: [{
        item_list: {
          items: [{
            name: "testitem",
            sku: "12345",
            price: "1.00",
            currency: "USD",
            quantity: 1
          }]
        },
        amount: {
          total: "1.00",
          currency: "USD"
        },
        description: "This is the payment transaction description."
      }],
      redirect_urls: { "return_url": "http://www.google.com/", "cancel_url": "http://localhost:4200/#/myorders" }

    };
    const success = this.getPaymentSuccess.bind(this);
    const error = this.onErrorPayment.bind(this);
    this.common.http.post('createPayment', body, success, error);

  }
  getPaymentSuccess(data): any {
    this.paymentIDForPay = data.paymentID;
    window.open("https://www.sandbox.paypal.com/checkoutnow?sessionID=uid_9851113d76_mtq6mtq6ndy&buttonSessionID=uid_a75408bb1b_mtq6mzi6mje&fundingSource=paypal&buyerCountry=IN&locale.x=en_US&commit=true&clientID=AZDxjDScFpQtjWTOUtWKbyN_bDt4OgqaF4eYXlewfBP4-8aqX3PiV8e1GWU6liB2CUXlkA59kJXE7M6R&env=sandbox&sdkMeta=eyJ1cmwiOiJodHRwczovL3d3dy5wYXlwYWwuY29tL3Nkay9qcz9jbGllbnQtaWQ9c2IiLCJhdHRycyI6eyJkYXRhLXVpZCI6InVpZF9jYnRrbWJucW92em50bW1pc2V3eXdmcnFjY3lwenMifX0&xcomponent=1&version=5.0.247&token=6CS572371C8896444", "_blank");
    const body = {
      "paymentID": this.paymentIDForPay
    }
    const success = this.getPaymentExecuteSuccess.bind(this);
    const error = this.onErrorExecutePayment.bind(this);
    this.common.http.post('executePayment', body, success, error);

  }
  getPaymentExecuteSuccess(data): any {
    //console.log()
  }
  onErrorExecutePayment(data) {
    //console.log(data)

  }
  onErrorPayment(data) {
    //console.log(data)
  }
  handleOtherPayment() {
    this.snack.open('Coming soon', 'Ok', { duration: 2000 });
  }
  /**
   * @remarks Get Delivery Address
   * @author  
   * @version 0.1
  */
  getDeliverAddressDetails(): any {
    this.spinner.show()
    const body = {
      "login": true,
      "customer_id": this.logedCustomerId,
      "session_id": this.logedSeeionId,
      "op_delivery_type": "retrive",
      "address_no": 1
    };
    const success = this.getDeliverAddressDetailsSuccess.bind(this);
    const error = this.getDeliverAddressDetailsError.bind(this);
    this.common.http.post('Customer_details', body, success, error);
  }

  getDeliverAddressDetailsSuccess(data): any {
    console.log('datasucsedetails', data);
    this.spinner.hide()
    this.placeOrdObj.GetDeliveryAddressData = Array<GetDeliveryAddrDetails>();
    if (data.delivery_details.res_status === false) {
      this.noAddressFoundFlag = true;
    }
    else {
      debugger
      this.noAddressFoundFlag = false;
      data.delivery_details.map((item) => {
        // if(item.default_address === 0 ){
        //   this.selectedAddressForDelivery = item.address_id
         
        //  }
        const dataset = new GetDeliveryAddrDetails();
        dataset.firstName = item.firstname;
        dataset.lastName = item.lastname;
        dataset.addressType = item.address_type;
        dataset.defaultAddress = item.default_address;
        dataset.customerId = item.customer_id;
        dataset.addressId = item.address_id;
        dataset.company = item.company;
        dataset.address1 = item.address_1;
        dataset.address2 = item.address_2;
        dataset.city = item.city;
        dataset.postcode = item.postcode;
        dataset.countryName = item.country_name;
        dataset.countryId = item.country_id;
        dataset.zoneName = item.zone_name;
        dataset.zoneId = item.zone_id;
        dataset.mobile = item.mobile;
        console.log('if condition is_selected111')
        if(item.is_selected === 'true' ){
          this.selectedAddressForDelivery = item.address_id
          this.addressDetails = dataset
          this.addressFlag = true
          console.log('if condition is_selected')
         } else if(this.addressFlag === false){
          if( item.default_address === 0 ){
            this.selectedAddressForDelivery = item.address_id
            this.addressDetails = dataset
            console.log('else condition default_address')
          }
         }
        this.placeOrdObj.GetDeliveryAddressData.push(dataset);
        console.log('this.placeOrdObj.GetDeliveryAddressData,,,,',this.placeOrdObj.GetDeliveryAddressData);
      });
      this.addressDiv.nativeElement.scrollTop = 0
    }

  }
  getDeliverAddressDetailsError(data): any {

  }

  getPaymentMethods(): any {

    const body = { "delivery_op_type": "select" };
    const success = this.getPaymentMethodSuccess.bind(this);
    const error = this.onErrorGetPaymentMethod.bind(this);
    this.common.http.post('delivery_mode', body, success, error);

  }

  getPaymentMethodSuccess(data): any {

    console.log('delmodes', data);

    if (data.res_status === true) {
      this.paymentMethods = data.data;
    }
    else {
      this.snack.open('Not available this delivery mode', 'Ok', { duration: 2000 });
    }
  }
  onErrorGetPaymentMethod(data) {
    console.log(data.msg);
  }
  selectedAddress(addrId): any {
    debugger
    console.log('addrId....', addrId)
    this.addressDetails = addrId;
    this.selectedAddressForDelivery = addrId.addressId;
    this.signupForm.reset();
   
    this.newaddressform.firstname = addrId.firstName
    this.newaddressform.lastname = addrId.lastName
    this.newaddressform.pincode = addrId.postcode
    this.newaddressform.address = addrId.address1
    this.newaddressform.city = addrId.city
    this.newaddressform.country = addrId.countryId
    this.newaddressform.zone = addrId.zoneId
    this.newaddressform.mobile = addrId.mobile
    this.newaddressform.addresstype = addrId.addressType
    this.newaddressform.default_address = addrId.defaultAddress
    console.log('express', this.selectedDeliveryFlag)
    this.newAddressSubmit('update')
    this.getOrderProductSummary(this.selectedDeliveryFlag);
   console.log('  this.addressDetails ',  this.addressDetails )
  }
  addressUpdate(data){

  }
  selectDeliveryMode(delivMode) {
    ////debugger

    if (this.selectedAddressForDelivery === undefined) {
      this.snack.open('Please select delivery address to proceed', 'Ok', { duration: 3000 });
    }
    // else if(delivMode === 2 || delivMode === 3){
    //   this.snack.open('Please select c&s mode to proceed', 'Ok', {duration: 3000});
    // }
    // else{
    // this.selectedDeliveryMode= delivMode;
    // //this.getEstimatedDelDetails();
    // this.resultOfDeliveryModeSuccess = true;
    // this.getOrderProductSummary();
    // }
  }
  selectDeliveryMode2(delivMode, cId, pId) {
    //   var x = delivMode;
    // var y = +x;

    // if (this.selectedAddressForDelivery === undefined) {
    //   this.snack.open('Please select delivery address to proceed', 'Ok', { duration: 3000 });
    // }


    this.cId = cId;
    this.pId = pId;
    this.getDeliveryCharges = delivMode;
    console.log('delmode....', this.getDeliveryCharges);
    this.eventTriggered = true;
    this.getOrderProductSummary('selected');
  }


  getEstimatedDelDetails(): any {
    ////debugger
    const body = {
      "delivery_op_type": "select", "delivery_mode_id": this.selectedDeliveryMode, "login": true, "customer_id": this.logedCustomerId,
      "sessoin_id": this.logedSeeionId, "source_zipcode": 500081, "address_id": this.selectedAddressForDelivery
    };
    const success = this.getEstimatedDelDetailsSuccess.bind(this);
    const error = this.onErrorgetEstimatedDelDetails.bind(this);
    this.common.http.post('est_delivery_dtls', body, success, error);
  }

  getEstimatedDelDetailsSuccess(data): any {
    //////debugger
    if (data.res_status === true) {
      // debugger
      this.estimatedDeliveryDtls = data.data;
      this.deliveryCharges = this.estimatedDeliveryDtls[0].delivery_charges;
      console.log('estideli', this.deliveryCharges);
      // this.getOrderProductSummary();
    }
    else {
      this.snack.open(data.msg, 'Ok', { duration: 2000 });
    }
  }
  onErrorgetEstimatedDelDetails(data) {
    console.log(data.msg)
  }
  /**
   * @remarks Get order product Summary
   * @author  
   * @version 0.1
   */

  getOrderProductSummary(data): any {
    console.log('sta,E',data)
    this.expressFlag = data;
    this.selectedDeliveryFlag = data;
    if(data !== undefined ){
      this.proceedFlag = true;
    }
    console.log('express flag', this.expressFlag)
    console.log(this.cartData)
    // const delCharges = parseInt(this.deliveryCharges);
    // if (this.selectedAddressForDelivery === undefined) {
    //   this.snack.open('Please select shipping address to proceed', 'Ok', { duration: 3000 });
    // }
    console.log('delivery charge', this.getDeliveryCharges)
    console.log('order summary currency', this.currencyId)
    let body: any;
    if ( this.expressFlag === 'Standard') {
      this.spinner.show();
      this.deliveryTypeList = [];
      body = {
        // "currency_id": this.currencyId
        "preview_op_type": "order_summery", "customer_id": this.logedCustomerId, "login": true, "session_id": this.logedSeeionId,
        "address_id": this.selectedAddressForDelivery, "delivery_details": [], "voucher_applied": false, "tenant_id": this.tenantId
      };
      body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), this.getCoupRes)
    } else if ( this.expressFlag === 'Express') {
      this.spinner.show();
      // this.expressFlag = true;
      // const deliveryList = [];
      this.deliveryTypeList = [];
      this.cartData.map((element) => {
        // this.deliveryTypeList.push({cart_id: this.cId, product_id: this.pId, delivery_type: this.getDeliveryCharges})
        this.deliveryTypeList.push({ cart_id: element.cart_id, product_id: element.product_id, delivery_type: 'Express Delivery' })
      })
      body = {
        // "currency_id": this.currencyId
        "preview_op_type": "order_summery", "customer_id": this.logedCustomerId, "login": true, "session_id": this.logedSeeionId,
        "address_id": this.selectedAddressForDelivery, "delivery_details": this.deliveryTypeList, "voucher_applied": false, "tenant_id": this.tenantId
      };
      body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), this.getCoupRes)
    }
    else {
      this.deliveryTypeList = [];
      console.log('select condition deliverytypelist', this.deliveryTypeList)
      console.log('select condition summaryData', this.summaryData)

      this.summaryData.cart_details.map((element) => {
        if (element.cart_id === this.cId) {
          this.deliveryTypeList.push({ cart_id: this.cId, product_id: this.pId, delivery_type: this.getDeliveryCharges })
        } else {
          this.deliveryTypeList.push({ cart_id: element.cart_id, product_id: element.product_id, delivery_type: element.delivery_type })
        }
      })
      console.log('delivery Type', this.deliveryTypeList);
      // deliveryCharges.push({cart_id: this.cId, product_id: this.pId, delivery_type: this.getDeliveryCharges})
      body = {
        "preview_op_type": "order_summery", "customer_id": this.logedCustomerId, "login": true, "session_id": this.logedSeeionId,
        "address_id": this.selectedAddressForDelivery, "delivery_details": this.deliveryTypeList, "voucher_applied": false
      };
      body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), this.getCoupRes)
    }

    console.log('asd...', body);
    const success = this.getOrderProductSummarySuccess.bind(this);
    const error = this.onErrorgetgetOrderProductSummary.bind(this);
    this.common.http.post('Preview', body, success, error);
  
  }

  getOrderProductSummarySuccess(data): any {
    this.vocherFlag = false;

    this.summaryData = data.Data;
    this.spinner.hide();
    console.log('ordersummarydatapreview', this.summaryData);
    if (data.res_status) {
      this.aa = true;
      this.safeDiv = true;
      this.summaryData.cart_details.map((element, idx) => {
        const imgObj = element.image;
        const image = JSON.parse(imgObj);
        if ('file_data' in image) {
          const imgArr = image.file_data;
          imgArr.map((ele) => {
            if (ele.storage_type === 'db') {
              this.summaryData.cart_details[idx].imageUrl = 'data:image/png;base64,' + ele.media_data;
            } else {
              this.summaryData.cart_details[idx].imageUrl = ele.media_data;
            }
          });
        }
        else {
          this.summaryData.cart_details[idx].imageUrl = image.data;
        }
      });


      // this.summaryData.cart_details.map((element, idx)=>{
      //   const imageParse = JSON.parse(element.image);
      //     if (imageParse.file_data) {
      //       imageParse.file_data.map((itemImg => {
      //         element[idx].imageUrl = itemImg.media_data;
      //       }));
      //     }
      //     else {
      //       element[idx].imageUrl = imageParse.data;
      //     }
      // })

      this.resultOfDeliveryModeSuccess = false;
      this.taxDetails = true;
      this.orderedProductEntireDetails = data.Data;
      console.log('estorddata', this.orderedProductEntireDetails);
      this.detailsForConfirmOrder = data;
      console.log("ordered products483", this.orderedProductEntireDetails);
      this.deliveryOptionList = data.Data.shipping_methods;
       this.amountgettingStr = this.summaryData.grand_total;
       this.currencyCode = this.summaryData.code
      //  console.log("ordered amountgettingStr",this.amountgettingStr);
      this.resultOfDeliveryModeSuccess = false;
      this.taxDetails = true;
      this.orderedProductEntireDetails = data.Data;
      console.log('estorddata', this.orderedProductEntireDetails);
      this.detailsForConfirmOrder = data;
      console.log("ordered products", this.orderedProductEntireDetails);
      // this.amountgettingStr = this.orderedProductEntireDetails.total;
    }
  }



  onErrorgetgetOrderProductSummary(data) {
    this.resultOfDeliveryModeSuccess = false;
    console.log(data.msg)
  }
  // checkState(button){
  //  // currentCheckedValue = null;
  // }
  handlePlaceorder() {
    console.log(this.deliveryMode, this.deladdress)
    this.orderConfirmFlag = false;
      this.orderexpandDiv = true;
      this.paymentFlag = true;
      this.inactiveSummary = true;
      this.ordersummChangeDiv = false;
      // this.stepper.next();
      // this.amountgettingStr = data.total_amount;
      
      this.codFlag =false
      this.razorFlag = false
     this.changeaddClk = false;
    this.shippAdd = false;
    this.newAddForm = false;
    this.addressShipView = true;


    // if(this.deladdress === undefined){
    //   this.snack.open('Please select delivery address to proceed', 'Ok', {duration: 3000});
    // }
    // else if(this.deliveryMode === undefined || this.deliveryMode === null){
    //   this.snack.open('Please select delivery mode to proceed', 'Ok', {duration: 3000});
    // }
    // else if(this.deliveryMode === 2 || this.deliveryMode === 3 ){
    //   this.snack.open('Not avaliable this deliver mode', 'Ok', {duration: 3000});
    // }
    
    // this.orderConfirmed();
  }
  /**
 * @remarks Store Validations
 * @author  Devi
 * @version 1.0
 */
  // storeValidation(body) {
  //   const storeId = sessionStorage.getItem('StoreId')
  //   console.log('selected in storeid in product details', storeId)

  //   if (this.storeId == null || this.storeId === 'all') {
  //     if (this.currencyId == null) {
  //       if (this.couponFlag === 'false') {
  //         body['coupon_applied'] = false
  //         return body;
  //       } else {
  //         body['coupon_applied'] = true
  //         body['coupon_details'] = [this.getCoupRes]
  //         return body;
  //       }

  //     } else {
  //       if (this.couponFlag === 'false') {
  //         body['currency_id'] = parseInt(this.currencyId);
  //         body['coupon_applied'] = false
  //         return body;
  //       } else {
  //         body['currency_id'] = parseInt(this.currencyId);
  //         body['coupon_applied'] = true
  //         body['coupon_details'] = [this.getCoupRes]
  //         return body;
  //       }
  //     }
  //     // return body;
  //   } else {
  //     if (this.currencyId == null) {
  //       if (this.couponFlag === 'false') {
  //         body['coupon_applied'] = false
  //         body['store_id'] = this.storeId;
  //         return body;
  //       } else {
  //         body['store_id'] = this.storeId;
  //         body['coupon_applied'] = true
  //         body['coupon_details'] = [this.getCoupRes]
  //         return body;
  //       }
  //     } else {
  //       if (this.couponFlag === 'false') {
  //         body['currency_id'] = parseInt(this.currencyId);
  //         body['store_currency_flag'] = 1
  //         body['coupon_applied'] = false
  //         body['store_id'] = this.storeId;
  //         return body;
  //       } else {
  //         body['currency_id'] = parseInt(this.currencyId);
  //         body['store_currency_flag'] = 1
  //         body['store_id'] = this.storeId;
  //         body['coupon_applied'] = true
  //         body['coupon_details'] = [this.getCoupRes]
  //         return body;
  //       }
  //     }
  //     // body['store_id'] = this.storeId ;
  //     // return body;
  //   }
  // }




  //order confirmation Details
  
  orderConfirmed(): any {
    this.spinner.show();
    const orederData = this.detailsForConfirmOrder;
    let body;
    let Url = sessionStorage.getItem('affiliateUrl')
    orederData.Data.cart_details.map((item) => {
      if (item.subscription_to_date === 0 || item.subscription_from_date === 0) {
        item['subscription_flag'] = 0;
      } else {
        item['subscription_flag'] = 1;
      }
    })
    console.log('affiliate Url', Url)

    body = {
      login: true,
      sessoin_id: this.logedSeeionId,
      customer_id: this.logedCustomerId,
      address_id: this.selectedAddressForDelivery,
      order_type: "affiliated",
      api_name: "ConfirmOrder",
      tenant_id: this.tenantId,
      Data: orederData.Data
    }
    body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), this.getCoupRes)
    body = this.common.referenceIdValidation(body)

    // if (Url !== null) {
    //   if (Url.includes('ref_id')) {
    //     const refId = Url.split('=')[1]
    //     console.log(refId);
    //     body = {
    //       "login": true,
    //       "sessoin_id": this.logedSeeionId,
    //       // "currency_id": 4,
    //       "customer_id": this.logedCustomerId,
    //       "address_id": this.deladdress,
    //       "order_type": "affiliated",
    //       "api_name": "ConfirmOrder",
    //       "ref_id": refId,
    //       "tracking_id": " ",
    //       "tenant_id": this.tenantId,
    //       // "store_id": 1,
    //       "url": Url,
    //       "Data": orederData.Data
    //     };
    //     body = this.storeValidation(body);
    //   } else if (Url.includes('t_id')) {
    //     const trackId = Url.split('=')[1]
    //     console.log(trackId);
    //     body = {
    //       "login": true,
    //       "sessoin_id": this.logedSeeionId,
    //       // "currency_id": 4,
    //       "customer_id": this.logedCustomerId,
    //       "address_id": this.deladdress,
    //       "order_type": "marketed",
    //       "api_name": "ConfirmOrder",
    //       "ref_id": 0,
    //       "tracking_id": trackId,
    //       "tenant_id": this.tenantId,
    //       // "store_id": 1,
    //       "url": Url,
    //       "Data": orederData.Data
    //     };
    //     body = this.storeValidation(body);
    //   }
    // } else {
    //   body = {
    //     "login": true,
    //     "sessoin_id": this.logedSeeionId,
    //     // "currency_id": 4,
    //     "customer_id": this.logedCustomerId,
    //     "address_id": this.deladdress,
    //     "order_type": "",
    //     "api_name": "ConfirmOrder",
    //     "ref_id": 0,
    //     "tracking_id": '',
    //     "tenant_id": this.tenantId,
    //     // "store_id": 1,
    //     "url": '',
    //     "Data": orederData.Data
    //   };
    //   body = this.storeValidation(body);
    // }
    console.log('confrm order payload', body);
    const success = this.getConfirmOrderSuccess.bind(this);
    const error = this.onErrorgetgetOrderConfirm.bind(this);
    this.common.http.post('ConfirmOrder', body, success, error);
  }

  getConfirmOrderSuccess(data): any {
    this.menu.passValue(data.cart_count)
    this.spinner.hide();
    if (data.res_status === true) {
            this.orderId = data.order_id;
      this.currencyIndicator = data;


    //   this.orderConfirmFlag = false;
    //   this.orderexpandDiv = true;
    //   this.paymentFlag = true;
    //   this.inactiveSummary = true;
    //   this.ordersummChangeDiv = false;

    //   this.codFlag =false
    //   this.razorFlag = false
    //  this.changeaddClk = false;
    // this.shippAdd = false;
    // this.newAddForm = false;
    // this.addressShipView = true;
    this.codClick();
    }
    else {
      this.snack.open(data.msg, 'Ok', { duration: 2000 });
    }
  }
  onErrorgetgetOrderConfirm(data) {
    console.log(data.msg)
  }

  handleGetCountries(): any {
    const body = {
      ordersdropdowns_op_type: "countries_dropdown"
    };
    const success = this.handleCountrySuccess.bind(this);
    const error = this.onErrorCountry.bind(this);
    this.common.http.post('countryandregionsdropdown', body, success, error);
  }
  handleCountrySuccess(data): any {
    //console.log('countries', data);
    this.countryInfo = data.country;
  }
  onErrorCountry(error): any {
    this.snack.open(error, 'ok', { duration: 3000 });
  }

  handleGetRegionByCountry(cId) {
    const body = {
      ordersdropdowns_op_type: "zone_dropdown", country_id: cId

    };

    const success = this.handleRegionSuccess.bind(this);
    const error = this.onErrorRegion.bind(this);
    this.common.http.post('countryandregionsdropdown', body, success, error);
  }

  handleRegionSuccess(data): any {
    console.log('countries', data);
    this.regionInfo = data.zone;


  }
  onErrorRegion(error): any {
    this.snack.open(error, 'ok', { duration: 3000 });
  }

  
  /**
   * @remarks Adding New Address Submit
   * @author  Ramana
   * @version 1.0
   */
  newAddressSubmit(data) {
    this.resultOfAddressInsertSuccess = true;
    let body;
    const defaultAdd = this.newaddressform.default_address === false ? 1 : 0
    if( this.newaddressform.firstname !== '' &&  this.newaddressform.lastname !== '' &&  this.newaddressform.address !== '' &&  this.newaddressform.mobile !== '' && 
    this.newaddressform.city !== '' &&   this.newaddressform.pincode !== '' && this.newaddressform.country !== '' &&  this.newaddressform.zone !== ''){
     if(data === 'add'){
      body = {
      login: true,
      customer_id: this.logedCustomerId,
      session_id: this.logedSeeionId,
      tenant_id: this.tenantId,
      op_delivery_type: "insert",
      new_details: [{
        firstname: this.newaddressform.firstname, lastname: this.newaddressform.lastname,
        address_1: this.newaddressform.address, address_2: '', city: this.newaddressform.city, mobile: this.newaddressform.mobile,
        postcode: this.newaddressform.pincode, country_id: parseInt(this.newaddressform.country),
        zone_id: parseInt(this.newaddressform.zone), address_type: this.newaddressform.addresstype, company: '',
        street_area: this.newaddressform.streetname, landmark: this.newaddressform.landmark, default_address: defaultAdd
      }]
    }
  }else{
    debugger
    body = {
      login: true,
      customer_id: this.logedCustomerId,
      session_id: this.logedSeeionId,
      tenant_id: this.tenantId,
      op_delivery_type: "update",
      address_no: this.selectedAddressForDelivery,
      new_details: [{
       
        firstname: this.newaddressform.firstname, lastname: this.newaddressform.lastname, address_id: this.selectedAddressForDelivery, 
        mobile: this.newaddressform.mobile,
        address_1: this.newaddressform.address, address_2: '', city: this.newaddressform.city, postcode: this.newaddressform.pincode, country_id: parseInt(this.newaddressform.country),
        zone_id: parseInt(this.newaddressform.zone), address_type: this.newaddressform.addresstype, company: '', 
        street_area: this.newaddressform.streetname, landmark: this.newaddressform.landmark, default_address: this.newaddressform.default_address , is_selected: 'true'
      }]
    }
    debugger
    console.log('bodyupdate', body);
  }
    const success = this.newaddressformSucess.bind(this);
    const error = this.newaddressformError.bind(this);
    this.common.http.post('Customer_details', body, success, error)
  }else{
    this.snack.open('Please fill all required fields', 'ok', { duration: 1500 });
  }
  }

  newaddressformSucess(data) {
    console.log('newaddress', data);
    if (data.res_status === false) {
      this.resultOfAddressInsertSuccess = false;
      this.snack.open(data.msg, 'Ok', { duration: 500 });
      this.getDeliverAddressDetails();
    } else {
      this.resultOfAddressInsertSuccess = true;
      this.newAddForm = false;
      this.shippAdd = true;
      this.snack.open(data.msg, 'Ok', { duration: 500 });
      this.getDeliverAddressDetails();
      this.closeNewAddressDialog();
      // let da = data.delivery_details;
      // this.addString = da[0].address_id;
    }
    // this.newaddressform.reset();
  }


  newaddressformError(data) {
    console.log(data);
  }

  handlePayments(data) {
    if (data === 'paypal') {
      this.paypalFlag = true;
      this.stripeFlag = false;
      this.razorFlag = false;
      this.codFlag = false;
    } else if (data === 'stripe') {
      this.stripeFlag = true;
      this.paypalFlag = false;
      this.razorFlag = false;
      this.codFlag = false;
    } else if (data === 'razor') {
      this.stripeFlag = false;
      this.paypalFlag = false;
      this.razorFlag = true;
      this.codFlag = false;
    } else {
      this.stripeFlag = false;
      this.paypalFlag = false;
      this.razorFlag = false;
      this.codFlag = true;
    }
  }
  paypalClk(paypalPopup) {
    this.dialogNewAddr = this.dialog.open(paypalPopup, { width: '45%', height: '305px', disableClose: true });
    this.confrmOrderFlag = true;
  }

  stripeClk(stripePop) {
    this.dialogNewAddr = this.dialog.open(stripePop, { width: '45%', height: '375px', disableClose: true });
    this.confrmOrderFlag = true;
  }
  validateForm() {
    console.log('onkeyupchanged');
  }
  keyup(event) {
    console.log('event', event);
    if (event.length <= 16) {
      this.does = true;
      this.lenth16more = false;
    }
    else if (event.length >= 17) {

      console.log('17');
      this.does = false;
      this.lenth16more = true;
    }

    else {
      console.log('eslse');
      // this.lenth16more = true;
      this.does = false;
    }
  }
  cvvup(event) {
    if (event.length <= 2) {
      this.cvverror = true;
    }
    else {
      this.cvverror = false;
    }

  }
  //  this.amo = this.orderedProductEntireDetails.data;
  //  orderedProductEntireDetails = amo.Data;



  // razorpay(){

  //   console.log('amountSting', this.amountgettingStr)
  //   let floatNuumber = parseFloat(this.amountgettingStr);
  //   console.log('floatNuumber', floatNuumber);
  //   let amountcurrency = floatNuumber *100;

  //   debugger
  //   this.options = {

  //       "key": "rzp_test_7HdkaZ1xFGPomB",
  //       "amount":  amountcurrency,
  //       "currency": "INR",
  //       "name": this.logedEmailId,
  //       "description": "Test Transaction",
  //       "image": "https://example.com/your_logo",
  //       "razorpay_order_id": this.orderId,
  //       "callback_url": "https://eneqd3r9zrjok.x.pipedream.net/",
  //       "prefill": {
  //           "name": "Gaurav Kumar",
  //           "email": "gaurav.kumar@example.com",
  //           "contact": "9999999999"
  //       },
  //       "notes": {
  //           "address": "Razorpay Corporate Office"
  //       },
  //       "theme": {
  //           "color": "#3399cc"
  //       }
  //     };


  //   console.log('asdfffff');

  //   this.rzp1 = new this.auth.nativeWindow.Razorpay(this.options);
  // console.log('afterasdfffff');
  // debugger
  // this.rzp1.open();
  // this.confrmOrderFlag = true;

  // //this.router.navigate(['/myorders']);

  // }


  rClick() {
    this.floatNuumber = parseFloat(this.amountgettingStr);
    this.amountcurrency = this.floatNuumber * 100;
    const body = {
      "amount": this.amountcurrency,
      "currency":  this.currencyCode
    }
    this.ps.getorderMenu(body).subscribe(
      (data) => {
        this.razorpayOrderId = data._id;
        console.log('razorordersucessID', this.razorpayOrderId);
        console.log('id', this.razorpayOrderId);
        this.razorpay() 
      }, (err) => {
        console.log('razororder error', err);
        this.snack.open(err, 'OK', { duration: 5000 })
      }
    
    );
  }

  razorpay() {
    this.floatNuumber = parseFloat(this.amountgettingStr);
    console.log('float number', this.floatNuumber)
    this.amountcurrency = this.floatNuumber * 100;
    const options: any = {
      // rzp_test_7HdkaZ1xFGPomB * rzp_test_s82NKjqnrIcU79
      key: 'rzp_test_s82NKjqnrIcU79',
      amount: this.amountcurrency, // amount should be in paise format to display Rs 1255 without decimal point
      currency:  this.currencyCode,
      name: this.logedEmailId, // company name or product name
      description: '',  // product description
      image: './assets/logo.jpg', // company logo or product image
      razorpay_order_id: this.razorpayOrderId, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    console.log('razorpay options', options);
    options.handler = ((response, error) => {

      options.response = response;
      console.log('Raz res', response);
      console.log('raz error', error);
      console.log('razorpay options11', options);
      this.snack.open(response.razorpay_payment_id, 'Ok', { duration: 2000 });
      this.snack.open(response.razorpay_order_id, 'Ok', { duration: 2000 });
      this.snack.open(response.razorpay_signature, 'Ok', { duration: 2000 });

      alert(response.razorpay_signature);
      alert(response.razorpay_signature);
      alert(response.razorpay_signature);



      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      console.log('Transaction cancelled.');
    });
    const rzp = new this.auth.nativeWindow.Razorpay(options);
    rzp.open();
  }



  codClick() {
    this.spinner.show()
    let body;
     body = {
      "customer_id": this.logedCustomerId,
      "session_id": this.logedSeeionId,
      "order_id": this.orderId,
      "tenant_id": this.tenantId,
      "payment_type": "COD", 
      "store_id": "",
      "api_name": "Cod",
    };
    body = this.common.referenceIdValidation(body)
    const success = this.codOnSucess.bind(this);
    const error = this.codOnError.bind(this);
    this.common.http.post('Cod', body, success, error);
  }
  sav(){
    this.shippAdd = false;
    this.newAddForm = false;
    this.addressShipView = true
    this.orderexpandDiv = true;
    this.inactiveSummary = true;
    this.orderConfirmFlag = true;
    this.ordersummChangeDiv = false;  
    this.shippD = false;
    this.changeaddClk = true;
    this.AddNAddrk = false;
    this.disabled = false;
  }
  codOnSucess(data) {
    this.spinner.hide()
    console.log('codsuc', data);
    if (data.res_status === true) {
      this.confrmOrderFlag = true;
      this.snack.open(data.msg_2, 'Ok', { duration: 5000 });
      this.router.navigate(['/myorders']);
    }else{
      this.snack.open(data.msg, 'Ok', { duration: 5000 });
    }
  }
  codOnError(data) {
    this.spinner.hide()
    console.log('coderror', data);
  }


  confirmorderClick() {
    this.clearCookies();
    this.router.navigate(['/myorders']);
  }
  clearCookies() {
    this.cookieService.deleteAll('../');
  }
  // Vouchers

  vocherAddArray() {
    //vocherEntercodeArray "VocherForm.VocherCode"

    let ca = [];

    let asdfg = this.VocherForm.value.VocherCode;
    this.vocherEntercodeArray.push(this.VocherForm.value.VocherCode);
  }

  applyVocherClks() {
    this.spinner.show();
    this.vocherFlag = true;
    let body;
    if (this.vocherEntercodeArray.length >= 1) {
      body = {
        preview_op_type: "order_summery", customer_id: this.logedCustomerId, address_id: this.selectedAddressForDelivery,
        login: true, session_id: this.logedSeeionId, voucher_applied: true,
        delivery_details: this.deliveryTypeList,
        voucher_details: { code: this.vocherEntercodeArray }
      };
      console.log('multiple vocher apply');
    }
    else {
      body = {
        preview_op_type: "order_summery", customer_id: this.logedCustomerId, address_id: this.selectedAddressForDelivery,
        login: true, session_id: this.logedSeeionId, voucher_applied: true,
        delivery_details: this.deliveryTypeList,
        voucher_details: { code: [this.VocherForm.value.VocherCode] }
      };
    }
    body = this.common.storeValidationWithCoupon(body, JSON.parse(this.couponFlag), this.getCoupRes)
    console.log(body)
    const success = this.VocherOnSuccess.bind(this);
    const error = this.VocheronError.bind(this);
    this.common.http.post('Preview', body, success, error);

    // change
    // chage end
  }

  VocherOnSuccess(data) {
    this.summaryData = data.Data;
    this.spinner.hide();
    // this.getOrderProductSummary('selected');
    if (data.res_status) {
    
      this.aa = true;
      this.safeDiv = true;
      this.resultOfDeliveryModeSuccess = false;
      this.taxDetails = true;
      this.orderedProductEntireDetails = data.Data;
      console.log('estorddata', this.orderedProductEntireDetails);
      this.detailsForConfirmOrder = data;
      console.log("ordered products483", this.orderedProductEntireDetails);
      this.deliveryOptionList = data.Data.shipping_methods;
      //  this.amountgettingStr = this.orderedProductEntireDetails.total;
      //  console.log("ordered amountgettingStr",this.amountgettingStr);
      this.resultOfDeliveryModeSuccess = false;
      this.taxDetails = true;
      this.orderedProductEntireDetails = data.Data;
      console.log('estorddata', this.orderedProductEntireDetails);
      this.detailsForConfirmOrder = data;
      console.log("ordered products", this.orderedProductEntireDetails);
      // this.amountgettingStr = this.orderedProductEntireDetails.total;
    }

  }
  VocheronError() {

  }
 
  shipptouch(){
    this.shippAdd = false;
    this.newAddForm = false;
    this.shippD = !this.shippD;
  }
  shipaddChangeClk(){
    this.shippAdd = true;
    this.newAddForm = false;
    this.addressShipView = false
    this.shippD = true;
    this.changeaddClk = false;
    this.AddNAddrk = true;
    this.getDeliverAddressDetails()
  }
  AddNAddrBtnClk(){
    this.shippAdd = false;
    this.newAddForm = true;
    this.addressShipView = false
    this.changeaddClk = true;

    this.shippD = false;

   

    this.AddNAddrk = false;

  }
  Ordersumtouch(){
    this.orderexpandDiv = !this.orderexpandDiv;
    this.orderConfirmFlag = !this.orderConfirmFlag;
    this.ordersummChangeDiv = false;
  }
  orderSumChangeClk(){
    this.orderexpandDiv = false;
    this.inactiveSummary = true;
    this.orderConfirmFlag = false;
    this.ordersummChangeDiv = !this.ordersummChangeDiv;
    
    this.disabledp = false;
  }
  paymenttouch(){
    this.paymentexpandDiv = !this.paymentexpandDiv;
  }
  btshoping(){
    this.router.navigate(['/home']);
  }
  

}
