import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';
import { environment } from '../../environments/environment';
import { CommonService } from './common.service';
import { UtilsService } from './utils.service';

function _window(): any{
  return window;

}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  get nativeWindow(): any{
    return _window();
  }
  oauthAccessResourceUrl;
  abcd
  constructor() { 
    this.oauthAccessResourceUrl = 'https://demoapps.arkatiss.com/idm/SSOAuthenticationController/SSOAuthentication'
  }
  private strRandom(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


generateCodeChallenge(): string {
   // const codeVerifier = this.strRandom(128);
    const codeVerifier = '2b536e835d834afbadbc2d3daeb2acc114d855c9d52147e79f7adc4c1ff916c7';
    sessionStorage.setItem('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    //console.log(codeChallenge, codeVerifier);
    return codeChallenge;
  }

  getLoginPage(): void {
    //debugger latest***************************//////
   
    const windowUrl = environment.windowUrl;
    const str = windowUrl.split('#/');
    const callBackURL = str[0];
    const codeChallenge = this.generateCodeChallenge();
    this.abcd = "google&";
    console.log('asd',this.abcd);
    const params = ['from=' + this.abcd + 'code_challenge=' + codeChallenge + '&' + 'redirect_url= ' + callBackURL + '#/home' + '&' + 'reset=' + true];
    const url = this.oauthAccessResourceUrl + '?' + params.join('&');
    console.log(url)
    window.location.href = url;
   
   
  }

  getHomePage(): void {
   
    const codeVerifier = sessionStorage.getItem('codeVerifier');
    // //console.log(codeVerifier);
    const params = [
      'code_verifier=' + codeVerifier,
     // 'authorization_code=' + authCode
    ];
    const url = this.oauthAccessResourceUrl + '?' + params.join('&');
  console.log(url);
  //debugger
    window.location.href = url;
    
  }
  // sociallogin(): any {

  //   const infoo = this.utils.getUserInfo();
  //   const fName = infoo.firstName;
  //   const lName = infoo.lastName;
  //   const fullName = infoo.firstName + infoo.lastName;
  //   const useEmail = infoo.email;


  //   const body = {
  //     "login_type": "social", "firstname": fName, "lastname": lName,
  //     "username": useEmail, "password": "", "cookie": 1
  //   };
  //   const success = this.socialloginSuccess.bind(this);
  //   console.log('bforbody', body);
  //   const error = this.socialloginerror.bind(this);
  //   this.common.http.post('customerlogin', body, success, error);
  // }
  // socialloginSuccess(data) {
  //   console.log(data);
  //   console.log('botcoomsucess');

  //   // const usersocialemaild = data['data'].user_name;
  //   //   sessionStorage.setItem('user_name', usersocialemaild);
  //   //   const socialsessionid = data['data'].session_id;
  //   //   sessionStorage.setItem('session_id', socialsessionid);



  //   const userdata = data['data'].customer_name;
  //   sessionStorage.setItem('userdata', userdata);

  //   const usersessionId = data['data'].session_id;
  //   sessionStorage.setItem('sessionId', usersessionId);

  //   const userCustomerId = data['data'].customer_id;
  //   sessionStorage.setItem('customerId', userCustomerId);


  //   const useremaild = data['data'].user_name;
  //   sessionStorage.setItem('userEmail', useremaild);

  //   const firstname = data['data'].firstname;
  //   sessionStorage.setItem('firstname', firstname);

  //   const lastname = data['data'].lastname;
  //   sessionStorage.setItem('lastname', lastname);

  //   const mobileno = data['data'].telephone;
  //   sessionStorage.setItem('telephone', mobileno);
  //   // this.getHomePage();

  //   this.router.navigate(['/home']);




  // }
  // socialloginerror(data) {
  //   console.log(data);
  //   console.log('botcoomerror');

  // }



}
