import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()

export class ADOrdersInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // //console.log(req);
        // const token = 'abcdefgh-123456789';
        req = req.clone({ headers: req.headers.append('Content-Type', 'application/json')});
        return next.handle(req);
    }
}
