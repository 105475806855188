<!-- <div class="row mt-3 ml-5 mr-5">
    <div class="col-sm-2 py-2 px-2">
        <app-account></app-account>
    </div>
    <div class="col-sm-7 px-2 py-2">
        <ngx-spinner class="spinnerCss">
            <p class="spinnerText">Loading....</p>
        </ngx-spinner>
        <div class="row card m-0 p-row">
            <div class="col-lg-12 col-sm-12 p-0">
                <p class="personal-info">
                    Order History
                </p>
            </div>
        </div>
        <div class="col-sm-12 p-0 detaldiv1">
            <div class="card p-0 ">
                <div *ngIf="noItemImage">
                    <img src="{{imgurl}}noitems-found.jpg" alt="" class="pointer">
                </div>
                <div class="card m-3 " *ngFor="let product of this.ord.data"
                    [ngClass]="product.productDetails.length > 1 ? 'order-card1' : 'order-card'">
                    <div class="card-header p-0 card-head">
                        <div class="row m-0 p-3">
                            <div class="col-sm-6 p-0">
                                <p class="card-head-p1">Order #: {{product?.orderId}}</p>
                            </div>
                            <div class="col-sm-6 p-0 text-right">
                                <p class="card-head-p2 pointer" (click)="orderDetails(product)">View Full Details</p>
                            </div>
                        </div>
                        <div class="row m-0 bg-white p-3" *ngIf="product.productDetails.length >1">
                            <div class="col-sm-7">
                                <p class="order-status">Order Status: {{product.orderStatus}}</p>
                            </div>
                            <div class="col-sm-5 text-right">
                                <p class="order-date">Order On: {{product.orderedDate}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="card-body cardInOrderHistory" *ngIf="product.productDetails.length === 1">
                        <div class="row m-0 mt-3" *ngFor="let item of product.productDetails">
                            <div class="col-sm-2 p-0 imgaligin" (click)="orderDetails(item)">
                                <img src={{item?.image}} class="img-responsive oreredImg" />
                            </div>
                            <div class="col-sm-6 p-0">
                                <p class="order-status">Status: {{item?.itemStatus}} </p>
                                <p class="mb-1 product-name">{{item?.name}}</p>
                                <p class="p-price"> {{item?.currencyIndicator}}{{item?.price }}</p>
                                <p *ngIf="item?.subFromDate != '0'">
                                    <span>Subscription Active</span>&nbsp;
                                    <span>
                                        <img src="assets/images/info.png" [matMenuTriggerFor]="menu"
                                            #menuTrigger="matMenuTrigger">
                                    </span>
                                    <mat-menu #menu="matMenu">
                                        <div class="row m-2">
                                            <div class="col-sm-8 p-0">
                                                <p class="sub-head pl-3">Subscription Summary</p>
                                            </div>
                                            <div class="col-sm-4 text-right"><i
                                                    class="fa fa-times fa-close curser close-ico" aria-hidden="true"
                                                    (click)="menu.closeMenu();"></i></div>
                                        </div>
                                        <hr class="p-hr1">
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Subscription Valid From</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price"> {{item?.subFromDate.split(' ')[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Subscription Valid Up To</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price">{{item?.subToDate.split(' ')[0]}}</span>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Delivery Interval</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price">{{item?.subCycle}}
                                                    {{item?.subDuration}}</span>
                                            </div>
                                        </div>
                                    </mat-menu>
                                </p>
                                <p *ngIf="item?.recurringId > 0">
                                    <span>Recurring Active</span>&nbsp;
                                    <span>
                                        <img src="assets/images/info.png" [matMenuTriggerFor]="recurringmenu"
                                            #menuTrigger="matMenuTrigger">
                                    </span>
                                    <mat-menu #recurringmenu="matMenu">
                                        <div class="row m-2">
                                            <div class="col-sm-8 p-0">
                                                <p class="sub-head pl-3">Recurring Summary</p>
                                            </div>
                                            <div class="col-sm-4 text-right"><i
                                                    class="fa fa-times fa-close curser close-ico" aria-hidden="true"
                                                    (click)="menu.closeMenu();"></i></div>
                                        </div>
                                        <hr class="p-hr1">
                                        <div class="col-sm-12 text-center">
                                            <p class="sub-col text-center">{{item?.recurringName}}</p>
                                        </div>
                                    </mat-menu>
                                </p>
                            </div>
                            <div class="col-sm-4 orderStatusDiv">
                                <p class="order-date">Ordered On: {{item?.orderedDate}}</p>
                                <div *ngIf="item?.paymentStatus == 'Pending' && item?.itemStatus == 'Draft' "
                                    class="mt-3">
                                    <button type="button"
                                        class="btn btn-outline-warning btn-sm cancel">Cancel</button>&nbsp;
                                    <button type="button" class="btn btn-warning btn-sm repay"
                                        (click)="repayClick(paymentPopup, product.orderId)">Repay</button>
                                </div>
                                <div *ngIf="item?.itemStatus == 'Order_Processing'" class="mt-3">
                                    <button type="button"
                                        class="btn btn-outline-warning cancel btn-sm ">Cancel</button>&nbsp;
                                    <button type="button" class="btn btn-warning repay btn-sm"
                                        (click)="handleReorder(product.orderId,item.productId)">Reorder</button>
                                </div>
                                <div *ngIf="item?.itemStatus == 'Delivered'" class="mt-3">
                                    <button type="button" class="btn btn-outline-warning cancel btn-sm" id="returnid"
                                        (click)="handleReturn(product.orderId,item.productId, item.orderLineId)">Return
                                    </button>&nbsp;
                                    <button type="button" class="btn btn-warning repay btn-sm"
                                        (click)="handleReorder(product.orderId,item.productId)">Reorder</button>
                                </div>
                                <ng-template #paymentPopup>
                                    <h5 class="AddressPopupTitle mb-2 float-right" (click)="closepaymentDialog()">X</h5>
                                    <div class="p-fluid p-formgrid p-grid  m-0">
                                        <div class="row p-0 m-0">
                                        </div>
                                        <div class="row paymentDiv">
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="paypalBtn"
                                                    (click)="paypalClk(paypalPopup)"> <img src="{{imgurl}}paypalweb.svg"
                                                    alt="" class="paypalImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="RazorpayBtn"
                                                    (click)="razorpay()"><img src="{{imgurl}}razorpaywebnew.svg" alt=""
                                                    class="Razor img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="StripeBtn"
                                                    (click)="stripeClk(stripePop)"><img src="{{imgurl}}stripeweb.png"
                                                    alt="" class="stripeImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="visaBtn"
                                                    (click)=codClick(product.orderId)> Cash On Delivery
                                            </div>
                                        </div>
                                        <div class="col-12 p-4  float-right" *ngIf="confrmcodFlag === true">
                                            <button mat-raised-button type="submit"
                                                class="btn cnfrmOrderBtn p-2 float-right mt-1"
                                                (click)=confirmorderCodClick()>Confirm Order</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="card-body cardInOrderHistory detaldiv2" *ngIf="product.productDetails.length >1">
                        <div class="row m-0 mt-3" *ngFor="let item of product.productDetails">
                            <div class="col-sm-2 p-0 imgaligin pointer" (click)="orderDetails(item)">
                                <img src={{item?.image}} class="img-responsive oreredImg" />
                            </div>
                            <div class="col-sm-6 p-0">
                                <p class="order-status">Status: {{item?.itemStatus}} </p>
                                <p class="mb-1 product-name pointer">{{item?.name}}</p>
                                <p class="p-price"> {{item?.currencyIndicator}}{{item?.price }}</p>
                                <p *ngIf="item?.subFromDate != '0'">
                                    <span>Subscription Active</span>&nbsp;
                                    <span>
                                        <img src="assets/images/info.png" [matMenuTriggerFor]="menu"
                                            #menuTrigger="matMenuTrigger">
                                    </span>
                                    <mat-menu #menu="matMenu">
                                        <div class="row m-2">
                                            <div class="col-sm-8 p-0">
                                                <p class="sub-head pl-3">Subscription Summary</p>
                                            </div>
                                            <div class="col-sm-4 text-right"><i
                                                    class="fa fa-times fa-close curser close-ico" aria-hidden="true"
                                                    (click)="menu.closeMenu();"></i></div>
                                        </div>
                                        <hr class="p-hr1">
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Subscription Valid From</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price"> {{item?.subFromDate.split(' ')[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Subscription Valid Up To</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price">{{item?.subToDate.split(' ')[0]}}</span>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-sm-7">
                                                <span class="sub-col">Delivery Interval</span>
                                            </div>
                                            <div class="col-sm-5 text-right">
                                                <span class="sub-price">{{item?.subCycle}}
                                                    {{item?.subDuration}}</span>
                                            </div>
                                        </div>
                                    </mat-menu>
                                </p>
                                <p *ngIf="item?.recurringId > 0">
                                    <span>Recurring Active</span>&nbsp;
                                    <span>
                                        <img src="assets/images/info.png" [matMenuTriggerFor]="recurringmenu"
                                            #menuTrigger="matMenuTrigger">
                                    </span>
                                    <mat-menu #recurringmenu="matMenu">
                                        <div class="row m-2">
                                            <div class="col-sm-8 p-0">
                                                <p class="sub-head pl-3">Recurring Summary</p>
                                            </div>
                                            <div class="col-sm-4 text-right"><i
                                                    class="fa fa-times fa-close curser close-ico" aria-hidden="true"
                                                    (click)="menu.closeMenu();"></i></div>
                                        </div>
                                        <hr class="p-hr1">
                                        <div class="col-sm-12 text-center">
                                            <p class="sub-col text-center">{{item?.recurringName}}</p>
                                        </div>
                                    </mat-menu>
                                </p>
                            </div>
                            <div class="col-sm-4"></div>
                            <hr class="order-hr"></div>
                    </div>
                    <div class="card-footer footer-height bg-white" *ngIf="product.productDetails.length >1">
                        <div class="row m-0 footer-card-row ">
                            <div class="col-sm-6">
                                <span>Total Order Price:</span>
                                <span>{{product.totalOrderAmt}}</span>
                            </div>
                            <div class="col-sm-6 text-right">
                                <div *ngIf="product?.paymentStatus == 'Pending' && product?.orderStatus == 'Draft' "
                                    >
                                    <button type="button"
                                        class="btn btn-outline-warning btn-sm cancel">Cancel</button>&nbsp;
                                    <button type="button" class="btn btn-warning btn-sm repay"
                                        (click)="repayClick(paymentPopup, product.orderId)">Repay</button>
                                </div>
                                <div *ngIf="product?.orderStatus == 'Order_Processing'" >
                                    <button type="button"
                                        class="btn btn-outline-warning cancel btn-sm ">Cancel</button>&nbsp;
                                    <button type="button" class="btn btn-warning repay btn-sm"
                                        (click)="handleItemReorder(product)">Reorder</button>
                                </div>
                                <div *ngIf="item?.itemStatus == 'Delivered'">
                                    <button type="button" class="btn btn-outline-warning cancel btn-sm" id="returnid"
                                        (click)="handleItemReturn(product)">Return
                                    </button>&nbsp;
                                    <button type="button" class="btn btn-warning repay btn-sm"
                                        (click)="handleItemReorder(product)">Reorder</button>
                                </div>

                                <ng-template #paymentPopup>
                                    <h5 class="AddressPopupTitle mb-2 float-right" (click)="closepaymentDialog()">X</h5>
                                    <div class="p-fluid p-formgrid p-grid  m-0">
                                        <div class="row p-0 m-0">
                                        </div>
                                        <div class="row paymentDiv">
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="paypalBtn"
                                                    (click)="paypalClk(paypalPopup)"> <img src="{{imgurl}}paypalweb.svg"
                                                    alt="" class="paypalImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="RazorpayBtn"
                                                    (click)="razorpay()"><img src="{{imgurl}}razorpaywebnew.svg" alt=""
                                                    class="Razor img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="StripeBtn"
                                                    (click)="stripeClk(stripePop)"><img src="{{imgurl}}stripeweb.png"
                                                    alt="" class="stripeImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="visaBtn"
                                                    (click)=codClick(product.orderId)> Cash On Delivery
                                            </div>
                                        </div>
                                        <div class="col-12 p-4  float-right" *ngIf="confrmcodFlag === true">
                                            <button mat-raised-button type="submit"
                                                class="btn cnfrmOrderBtn p-2 float-right mt-1"
                                                (click)=confirmorderCodClick()>Confirm Order</button>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3  py-2 px-2">
        <app-flash-page></app-flash-page>
    </div>
</div>
<app-footer></app-footer> -->

<!--------------------------------------Old code------------------------------------------------------->

<div class="row mt-3 ml-5 mr-5">
  <div class="col-sm-2 py-2 px-2">
    <app-account></app-account>
  </div>
  <div class="col-sm-7 px-2 py-2">
    <ngx-spinner class="spinnerCss">
      <p class="spinnerText">Loading....</p>
    </ngx-spinner>
    <div class="row card m-0 p-row">
      <div class="col-lg-12 col-sm-12 p-0">
        <p class="personal-info">Order History</p>
      </div>
    </div>
    <div class="col-sm-12 p-0" *ngIf="noItemImage">
      <div class="card p-0">
        <div class="col-sm-6 offset-sm-3 mt-4 mb-3">
          <img
            src="{{ imgurl }}noImag.png"
            alt=""
            class="img-fluid d-block pl-2 pr-0 cartEmptyText"
          />
          <h4 class="heading m-2 p-2 text-center">Your Order List Is Empty</h4>
        </div>
      </div>
    </div>
    <div class="col-sm-12 p-0 detaldiv1">
      <div class="card p-0">
        <!-- <div *ngIf="noItemImage">
                    <img src="{{imgurl}}noitems-found.jpg" alt="" class="pointer">
                </div> -->
        <div class="card m-3 order-card" *ngFor="let product of this.ord.data">
          <div class="card-header">
            <div class="row m-0">
              <div class="col-sm-6 p-0">
                <p class="card-head-p1">Order #: {{ product?.orderId }}</p>
              </div>
              <div class="col-sm-6 p-0 text-right">
                <p class="card-head-p2 pointer" (click)="orderDetails(product)">
                  View Full Details
                </p>
              </div>
            </div>
          </div>
          <div class="card-body cardInOrderHistory">
            <div class="row m-0 mt-3">
              <div
                class="col-sm-2 p-0 imgaligin"
                (click)="orderDetails(product)"
              >
                <img
                  src="{{ product?.image }}"
                  class="img-responsive oreredImg"
                />
              </div>
              <div class="col-sm-6 p-0">
                <!-- <p class="order-status">Status: {{product?.itemStatus}}</p> -->
                <!-- <p class="order-status">
                                    <span  [ngClass]="{'process': product?.itemStatus === 'Order Processing' , 'cancelOrder': product?.itemStatus === 'Canceled', 'draft': product?.itemStatus === 'Draft'}"></span> <span>{{product?.itemStatus}}</span>
                                </p> -->
                <p class="mb-1 product-name" (click)="orderDetails(product)">
                  {{ product?.name }}
                </p>
                <p class="p-price">
                  {{ product?.currencyIndicator }}{{ product?.price }}
                </p>
                <p class="order-status">
                  <span
                    [ngClass]="{
                      process:
                        product?.itemStatus !== 'Order Cancelled' &&
                        product?.itemStatus !== 'Draft',
                      cancelOrder: product?.itemStatus === 'Order Cancelled',
                      draft: product?.itemStatus === 'Draft'
                    }"
                  ></span>
                  <span *ngIf="product?.itemStatus !== 'Order Cancelled'">{{
                    product?.itemStatus
                  }}</span>
                  <span *ngIf="product?.itemStatus === 'Order Cancelled'"
                    >{{ product?.itemStatus }} On
                    {{ product?.cancelledDate }}</span
                  >
                </p>
                <p *ngIf="product?.subFromDate != '0'">
                  <span class="recurring-active">Subscription Active</span
                  >&nbsp;
                  <span>
                    <img
                      src="assets/images/info.png"
                      [matMenuTriggerFor]="menu"
                      #menuTrigger="matMenuTrigger"
                    />
                  </span>
                  <mat-menu #menu="matMenu">
                    <div class="row m-2">
                      <div class="col-sm-8 p-0">
                        <p class="sub-head pl-3">Subscription Summary</p>
                      </div>
                      <div class="col-sm-4 text-right">
                        <i
                          class="fa fa-times fa-close curser close-ico pointer"
                          aria-hidden="true"
                          (click)="menu.closeMenu()"
                        ></i>
                      </div>
                    </div>
                    <hr class="p-hr1" />
                    <div class="row m-0">
                      <div class="col-sm-7">
                        <span class="sub-col">Subscription Valid From</span>
                      </div>
                      <div class="col-sm-5 text-right">
                        <span class="sub-price">
                          {{ product?.subFromDate.split(" ")[0] }}</span
                        >
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-sm-7">
                        <span class="sub-col">Subscription Valid Up To</span>
                      </div>
                      <div class="col-sm-5 text-right">
                        <span class="sub-price">{{
                          product?.subToDate.split(" ")[0]
                        }}</span>
                      </div>
                    </div>
                    <div class="row m-0">
                      <div class="col-sm-7">
                        <span class="sub-col">Delivery Interval</span>
                      </div>
                      <div class="col-sm-5 text-right">
                        <span class="sub-price"
                          >{{ product?.subCycle }}
                          {{ product?.subDuration }}</span
                        >
                      </div>
                    </div>
                  </mat-menu>
                </p>
                <p *ngIf="product?.recurringId > 0">
                  <span class="recurring-active">Recurring Active</span>&nbsp;
                  <span>
                    <img
                      src="assets/images/info.png"
                      [matMenuTriggerFor]="recurringmenu"
                      #menuTrigger="matMenuTrigger"
                    />
                  </span>
                  <mat-menu #recurringmenu="matMenu">
                    <div class="row m-2">
                      <div class="col-sm-8 p-0">
                        <p class="sub-head pl-3">Recurring Summary</p>
                      </div>
                      <div class="col-sm-4 text-right">
                        <i
                          class="fa fa-times fa-close curser close-ico pointer"
                          aria-hidden="true"
                          (click)="menu.closeMenu()"
                        ></i>
                      </div>
                    </div>
                    <hr class="p-hr1" />
                    <div class="col-sm-12 text-center">
                      <p class="sub-col text-center">
                        {{ product?.recurringName }}
                      </p>
                    </div>
                  </mat-menu>
                </p>
              </div>
              <div class="col-sm-4 orderStatusDiv text-right">
                <p class="order-date">Ordered On: {{ product?.orderedDate }}</p>

                <div
                  *ngIf="
                    product?.paymentStatus == 'Pending' &&
                    product?.itemStatus == 'Draft'
                  "
                  class="mt-3"
                >
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm cancel"
                    (click)="
                      cancelOrder(
                        product.orderId,
                        product.productId,
                        product.orderLineId
                      )
                    "
                  >
                    Cancel</button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-warning btn-sm repay"
                    (click)="repayClick(paymentPopup, product)"
                  >
                    Pay
                  </button>
                </div>
                <div
                  *ngIf="product?.itemStatus == 'Order Processing'"
                  class="mt-3"
                >
                  <button
                    type="button"
                    class="btn btn-outline-warning cancel btn-sm"
                    (click)="
                      cancelOrder(
                        product.orderId,
                        product.productId,
                        product.orderLineId
                      )
                    "
                  >
                    Cancel</button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-warning repay btn-sm"
                    (click)="handleReorder(product.orderId, product.productId)"
                  >
                    Reorder
                  </button>
                </div>
                <div *ngIf="product?.itemStatus == 'Delivered'" class="mt-3">
                  <button
                    type="button"
                    class="btn btn-outline-warning cancel btn-sm"
                    id="returnid"
                    (click)="
                      handleReturn(
                        product.orderId,
                        product.productId,
                        product.orderLineId
                      )
                    "
                  >
                    Return</button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-warning repay btn-sm"
                    (click)="handleReorder(product.orderId, product.productId)"
                  >
                    Reorder
                  </button>
                </div>
                <ng-template #paymentPopup>
                  <div class="row m-2">
                    <div class="col-sm-12 p-0">
                      <p class="card-head-p1">Payment Options</p>
                    </div>
                  </div>
                  <hr />
                  <div class="row m-2 ml-4 mt-4">
                    <!-- <div class="col-sm-3 p-0">
                                            <input type="radio" name="payment" id="paypalBtn"
                                                (click)="handlePayments('paypal')">
                                            <img src="{{imgurl}}paypal-Img.png" alt="" class="paypalImg img-fluid">
                                        </div>
                                        <div class="col-sm-3 p-0">
                                            <input type="radio" name="payment" id="StripeBtn"
                                                (click)="handlePayments('stripe')"><img src="{{imgurl}}stripeweb.png"
                                                alt="" class="stripeImg img-fluid">
                                        </div> -->
                    <div class="col-sm-3 p-0">
                      <input
                        type="radio"
                        name="payment"
                        id="RazorpayBtn"
                        (click)="handlePayments('razor')"
                      /><img
                        src="{{ imgurl }}razorpaywebnew.svg"
                        alt=""
                        class="Razor img-fluid"
                      />
                    </div>

                    <div class="col-sm-4 p-0">
                      <input
                        type="radio"
                        name="payment"
                        id="visaBtn"
                        (click)="handlePayments('cod')"
                      />
                      <span class="codBtn pl-2">Cash On Delivery</span>
                    </div>
                  </div>
                  <div class="col-sm-12 text-right mt-4">
                    <button
                      type="button"
                      class="btn btn-outline-warning btn-sm cancel"
                      (click)="dialog.closeAll()"
                    >
                      CANCEL</button
                    >&nbsp;
                    <button
                      type="button"
                      class="btn btn-warning btn-sm repay"
                      *ngIf="paypalFlag"
                      (click)="paypalClk(paypalPopup)"
                    >
                      PROCEED
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm repay"
                      *ngIf="stripeFlag"
                      (click)="stripeClk(stripePop)"
                    >
                      PROCEED
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm repay"
                      *ngIf="razorFlag"
                      (click)="rClick()"
                    >
                      PROCEED
                    </button>
                    <button
                      type="button"
                      class="btn btn-warning btn-sm repay"
                      *ngIf="codFlag"
                      (click)="codClick(product.orderId)"
                    >
                      PROCEED
                    </button>
                  </div>

                  <!--  <h5 class="AddressPopupTitle mb-2 float-right pointer" (click)="dialog.closeAll()">X
                                    </h5>
                                    <div class="p-fluid p-formgrid p-grid  m-0">
                                        <div class="row p-0 m-0">
                                        </div>
                                        <div class="row paymentDiv">
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="paypalBtn"
                                                    (click)="paypalClk(paypalPopup)"> <img src="{{imgurl}}paypalweb.svg"
                                                    alt="" class="paypalImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="RazorpayBtn"
                                                    (click)="rClick()"><img src="{{imgurl}}razorpaywebnew.svg" alt=""
                                                    class="Razor img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="StripeBtn"
                                                    (click)="stripeClk(stripePop)"><img src="{{imgurl}}stripeweb.png"
                                                    alt="" class="stripeImg img-fluid">
                                            </div>
                                            <div class="col-sm-3">
                                                <input type="radio" name="payment" id="visaBtn"
                                                    (click)=codClick(product.orderId)> Cash On Delivery
                                            </div>
                                        </div>
                                        <div class="col-12 p-4  float-right" *ngIf="confrmcodFlag === true">
                                            <button mat-raised-button type="submit"
                                                class="btn cnfrmOrderBtn p-2 float-right mt-1"
                                                (click)=confirmorderCodClick()>Confirm Order</button>
                                        </div>
                                    </div> -->
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3 py-2 px-2">
    <app-flash-page></app-flash-page>
  </div>
</div>
<app-footer></app-footer>

<ng-template #paypalPopup>
  <div class="row m-2">
    <div class="col-sm-12 p-0">
      <p class="card-head-p1">Card Info</p>
    </div>
  </div>
  <hr />
  <div class="row m-2 ml-4 mt-4">
    <div class="col-sm-8">
      <div class="row">
        <div class="col-sm-6">
          <label for="cname" class="cardLbl">Name on Card</label>
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            id="cname"
            name="cardname"
            placeholder="Enter Card Name"
          />
        </div>
        <br />
        <div class="col-sm-6">
          <label for="ccnum" class="cardLbl">Credit card number</label>
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            id="ccnum"
            name="cardnumber"
            placeholder="Enter Card Number"
          />
        </div>
        <br />
        <div class="col-sm-6">
          <label for="expmonths" class="cardLbl">Exp Month </label>
        </div>
        <div class="col-sm-6">
          <input
            type="text"
            id="expyear"
            name="expyear"
            placeholder="Enter expire Date on Card"
          />
        </div>
        <br />
        <div class="col-sm-6">
          <label for="cvv" class="cardLbl">CVV</label>
        </div>
        <div class="col-sm-6">
          <input type="text" id="cvv" name="cvv" placeholder="Enter CVV" />
        </div>
      </div>
    </div>
    <div class="col-sm-12 mt-4 ml-2 p-0">
      <button
        type="button"
        class="btn btn-outline-warning btn-sm cancel"
        (click)="dialog.closeAll()"
      >
        CANCEL</button
      >&nbsp;
      <button
        type="button"
        class="btn btn-warning btn-sm repay"
        (click)="dialog.closeAll()"
      >
        SUBMIT
      </button>
    </div>
  </div>

  <!-- <h5 class="AddressPopupTitle mb-2 float-right" (click)="dialog.closeAll()">X</h5>
    <div class="p-fluid p-formgrid p-grid  m-0">
        <div class="row p-0 m-0">
        </div>
        <div class="row ">
            <div class="row ">
                <div class="col-sm-6">
                    <label for="cname" class="cardLbl">Name on Card</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="cname" name="cardname" placeholder="Enter Card Name">
                </div>
                <div class="col-sm-6">
                    <label for="ccnum" class="cardLbl">Credit card number</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="ccnum" name="cardnumber" placeholder="Enter Card Number">
                </div>
                <div class="col-sm-6">
                    <label for="expmonths" class="cardLbl">Exp Month </label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="expyear" name="expyear" placeholder="Enter expire Date on Card">
                </div>
                <div class="col-sm-6">
                    <label for="cvv" class="cardLbl">CVV</label>
                </div>
                <div class="col-sm-6">
                    <input type="text" id="cvv" name="cvv" placeholder="Enter CVV">
                </div>
                <div class="col-sm-4">
                </div>
                <div class="col-sm-4">
                    <button mat-raised-button color="primary" class="submitButton">Submit</button>
                </div>
            </div>
        </div>
    </div> -->
</ng-template>

<ng-template #stripePop>
  <div class="row m-2">
    <div class="col-sm-12 p-0">
      <p class="card-head-p1">Card Info</p>
    </div>
  </div>
  <hr />
  <div class="row m-2 ml-4 mt-4">
    <div class="col-sm-12">
      <label class="form-label">Card Number</label><br />
      <input
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        placeholder="Enter Card Number"
        (keyup)="keyup($event.target.value)"
      />

      <div class="errormsg">
        <p *ngIf="does">Your card number is incomplete.</p>
        <p *ngIf="lenth16more">Card number is invalid</p>
      </div>
    </div>
    <br />
    <div class="col-sm-12 pt-3">
      <label class="form-label">Card Details</label><br />
      <input
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        placeholder="MM/YY"
      />
      <input
        type="text"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
        placeholder="CVV"
        (keyup)="cvvup($event.target.value)"
      />
      <p *ngIf="cvverror" class="errormsg">Invalid CVV</p>
    </div>
  </div>
  <div class="col-sm-12 mt-4 ml-4">
    <button
      type="button"
      class="btn btn-outline-warning btn-sm cancel"
      (click)="dialog.closeAll()"
    >
      CANCEL</button
    >&nbsp;
    <button
      type="button"
      class="btn btn-warning btn-sm repay"
      (click)="dialog.closeAll()"
    >
      SUBMIT
    </button>
  </div>

  <!-- <h5 class="AddressPopupTitle mb-2 float-right" (click)="dialog.closeAll()">X</h5>
    <div class="p-fluid p-formgrid p-grid  m-0">
        <div class="row">
            <div class="col-sm-4">
            </div>
            <div class="col-sm-4">
                <label>Card Info</label>
            </div>
            <div class="col-sm-4">
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <input type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    placeholder="Card Number" (keyup)="keyup($event.target.value)" />

                <div class="errormsg">
                    <p *ngIf="does"> Your card number is incomplete.</p>
                    <p *ngIf="lenth16more">Card number is invalid</p>
                </div>
            </div>
            <div class="col-sm-2">
                <input type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    placeholder="MM/YY" />
            </div>
            <div class="col-sm-2">
                <input type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    placeholder="CVV" (keyup)="cvvup($event.target.value)" />
                <p *ngIf="cvverror" class="errormsg">Invalid CVV</p>
            </div>
            <div class="col-sm-1">
            </div>
        </div>
    </div> -->
</ng-template>
