import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../helper/common.service';


@Component({
  selector: 'app-vocher-details',
  templateUrl: './vocher-details.component.html',
  styleUrls: ['./vocher-details.component.scss']
})
export class VocherDetailsComponent implements OnInit {

  constructor(private common: CommonService) { }
  VocherArray: any[];
  // custID;

  ngOnInit(): void {
    this.getVochers();
    // this.custID = sessionStorage.getItem('userdata');


  }
  /**
         * @remarks Get Vochers List
         * @author 
         * @version 0.1
         */

  getVochers() {
    const custID2 = sessionStorage.getItem('customerId');

    // ={"coupon_op_type": "view", "limit": 100, "offset":0, "product_id":"all",
    // "store_id":"all","category_id":"all"};

    const body = { "voucher_op_type": "sent", "customer_id": custID2 };
    console.log('body', body);

    const success = this.VochersOnSuccess.bind(this);
    const error = this.VochersonError.bind(this);
    this.common.http.post('VoucherUi', body, success, error);

  }
  VochersOnSuccess(data) {

    this.VocherArray = data.data;
    console.log('VochersucessArray', data);

  }
  VochersonError(data) {
    console.log('VocherError', data);
  }

}
