<p-carousel
class="landing-carousel"
[value]="weekdealsAdss"
[circular]="false"
[numVisible]="5"
[numScroll]="4"
[showIndicators]="false"
[responsiveOptions]="responsiveOptions"
>
<ng-template pTemplate="header">
  <h5 class="pl-4">Weekly Ads</h5>
</ng-template>
<ng-template let-img pTemplate="item" let-imgx = "index">
  <div #widgetsContent class="d-flex">
<div class="item-card mt-4 ml-4 p-0" *ngFor="let RelData of weekdealsAdss">
    <span class="text-right">
        <span *ngIf="RelData.wishlist_status === 1" [matMenuTriggerFor]="afterMenu">
            <!-- <i class="fa fa-heart heartIco product-Wishlist"></i> -->
            <img [src]="wishlistNone" alt="Wishlist" width="25px" height="25px" class="product-Wishlist" />
        </span>
        <mat-menu #afterMenu="matMenu" xPosition="before">
            <div mat-menu-item [disableRipple]="true" (click)="$event.stopPropagation()"
                class="saveMenuItem wishlistform">
                <div class="row p-0">
                    <div class="col-12 pt-2" *ngIf="foldervisible">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="font-10">
                                <mat-label>Folder Name</mat-label>
                                <input matInput placeholder="Enter Name" [(ngModel)]="folderName" name="foldername" />
                            </mat-form-field>
                        </div>
                        <div class="col-12 row m-0">
                            <div class="col-6 p-0">
                                <p class="newfolder text-center" (click)="hideFolder()">
                                    Back
                                </p>
                            </div>
                            <div class="col-6 pr-3" (click)="addFolder()">
                                <p class="newfolder text-center">Add</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 pt-2" *ngIf="formhide">
                        <form>
                            <div class="col-sm-12">
                                <mat-form-field class="font-10" appearance="outline">
                                    <mat-label>Folder</mat-label>
                                    <mat-select [(ngModel)]="folderList" name="folders">
                                        <mat-option *ngFor="let a of folders" [value]="a.folder_id">
                                            {{ a.folder_name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-12 row m-0">
                                <div class="col-6 p-0" (click)="showfolder()">
                                    <p class="newfolder text-center">New Folder</p>
                                </div>
                                <div class="col-6 pr-3" (click)="addtoWishlist(RelData, imgx, 'weekly')">
                                    <p class="newfolder text-center">Add</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </mat-menu>

        <span *ngIf="RelData.wishlist_status === 0">
            <!-- <i
                    class="fa fa-heart SelectedHeartIco product-Wishlist"
                    (click)="
                      removeWishlist(imgx, img.wishlist_status_id, 'weekly')
                    "
                  ></i> -->

            <img [src]="wishlistAdd" alt="Wishlist" width="25px" height="25px" class="product-Wishlist" (click)="
                      removeWishlist(
                        imgx,
                        RelData.wishlist_status_id, RelData,
                        'weekly'
                      )
                    " />
        </span>
    </span>

    <div class="product-img d-flex justify-content-center">
        <img src="{{ RelData.imageUrl }}" alt="watch-icon" height="200px"
            (click)="goToProductsView(RelData.product_id)" />
    </div>
    <div class="d-flex justify-content-between align-items-center">
        <span class="product-offer" *ngIf="RelData.discount_price !== '0.00'">
            <span>Save</span>
            <span [ngClass]="{ disPrice1: RelData.discount_price.length > 5 }" *ngIf="RelData.price_type === 'F'">
                {{ RelData.currency_indicator
                }}{{ RelData.discount_price | slice : 0 : 7 }}
            </span>
            <span [ngClass]="{ disPrice1: RelData.discount_price.length > 5 }" *ngIf="RelData.price_type === 'P'">
                {{ RelData.discount_price | slice : 0 : 7
                }}{{ RelData.symbol }}
            </span>
        </span>
        <span class="d-flex align-items-center star-rating">
            &nbsp; {{ RelData.average_rating }}&nbsp;
            <img src="assets/svg/star.svg" alt="star-logo" />&nbsp; |
            &nbsp; 5&nbsp;&nbsp;
        </span>
    </div>

    <div class="d-flex flex-column product-content">
        <div class="d-flex align-items-center justify-content-between pb-3"
            (click)="goToProductsView(RelData.product_id)">
            <span class="product-name" title="{{ RelData.name }}">{{
                RelData.name
                }}</span>
        </div>
        <div class="d-flex align-items-center justify-content-between">
            <span class="d-flex align-items-center">
                <span class="product-currency" *ngIf="RelData.discount_price !== '0.00'">
                    {{ RelData.currency_indicator
                    }}{{ RelData.discount_off_price }}
                </span>
                <span class="product-currency" *ngIf="RelData.discount_price == '0.00'">
                    {{ RelData.currency_indicator }}{{ RelData.org_price }}
                </span>
                <span class="OfferPricewek1 ml-1" *ngIf="RelData.discount_price !== '0.00'">
                    {{ RelData.currency_indicator }}{{ RelData.org_price }}
                </span>
            </span>

            <button class="add-cart" (click)="
            addToCartClick(
                        RelData.product_id,
                        RelData.org_price
                      )
                    ">
                ADD TO CART
            </button>
        </div>
    </div>
</div>
  </div></ng-template></p-carousel>
