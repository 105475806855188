<div class="row p-0">
  <div class="col-sm-12 p-0">
        <mat-accordion>
           <mat-expansion-panel *ngFor="let filter of filterattrData,let index=index" class="m-0 filterpanel" expanded>
              <mat-expansion-panel-header>
                <mat-panel-title class="font-14">
                  {{filter.attr_grp_name}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p *ngFor="let arr of filter.attribute_details" class="font-13">
                <mat-checkbox class="example-margin" [(ngModel)]="arr.attribute" (change)="changeFilter('attr',arr.attribute,index,arr.attribute_id)" >{{arr.attr_name}}</mat-checkbox>
               </p>
            </mat-expansion-panel>
          </mat-accordion> 
          <mat-accordion>
            <mat-expansion-panel expanded class="filterpanel">
               <mat-expansion-panel-header>
                 <mat-panel-title class="font-14" >
                    Brand
                 </mat-panel-title>
               </mat-expansion-panel-header>
               <p *ngFor="let m of filtermanufdetails,let index=index" class="font-13">
                <mat-checkbox class="example-margin" [(ngModel)]="m.namee" (change)="changeFilter('brand',m.namee,index,m.manufacturer_id)"value={{m.manufacturer_id}}>{{m.name}}</mat-checkbox>
              </p>
             </mat-expansion-panel>
           </mat-accordion> 
           <mat-accordion>
            <mat-expansion-panel expanded class="filterpanel mt-0">
               <mat-expansion-panel-header>
                 <mat-panel-title class="font-14">
                   Option Details
                 </mat-panel-title>
               </mat-expansion-panel-header>
               <p class="font-13" *ngFor="let o of optiondetails,let index=index">
                <mat-checkbox class="example-margin" [(ngModel)]="o.name" (change)="changeFilter('optiondetails',o.name,index,o.option_value_id)" value={{o.option_value_id}}>{{o.opt_val_name}}</mat-checkbox>
                </p>
             </mat-expansion-panel>
           </mat-accordion>
           <mat-accordion>
            <mat-expansion-panel expanded class="filterpanel">
               <mat-expansion-panel-header>
                 <mat-panel-title class="font-14">
                    Price
                 </mat-panel-title>
               </mat-expansion-panel-header>
               <span *ngFor="let p of filterpricelist,let index=index" class="font-13">
                <mat-checkbox class="example-margin"  (change)="changeFilter('price',p,index,p)" value={{p}}>{{p}}</mat-checkbox>
                </span>
             </mat-expansion-panel>
           </mat-accordion>
        </div>
    </div>
