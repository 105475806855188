<div class=" row mt-3 ml-5 mr-5">
  <div class="col-sm-2 py-2 px-2">
    <app-account></app-account>
  </div>
  <div class="col-sm-7 px-2 py-2">
    <ngx-spinner class="spinnerCss">
      <p class="spinnerText">Loading....</p>
    </ngx-spinner>

    <div class="row card m-0 p-row">
      <div class="col-lg-12 col-sm-12 p-0">
        <p class="personal-info">
          Affiliations
        </p>
      </div>
    </div>
    <div class="col-sm-12 p-0 ">
      <div class="card p-0">
        <div class="card-body">
          <p-table #dt1 [value]="this.ord.data" 
          responsiveLayout="scroll" [rowHover]="true" [rows]="3"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[3,10,25,50]"
            styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['type','productName','link','clicks', 'conversions']">
            <ng-template pTemplate="caption">
              <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                    placeholder="Search " />
                </span>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th>S.No</th>
                <th>Type</th>
                <th>Name</th>
                <th>URL</th>
                <th>Clicks</th>
                <th style="width: 125px">Conversions</th>
                <th>Action</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
              <tr>
                <td> {{getIndex(item)+1}}</td>
                <td>{{item.type}}</td>
                <td>{{item.productName}}</td>
                <td> 
                    <i class="fa fa-clone d-ico" aria-hidden="true" matTooltip="{{item.link}}" (click)="copyText(item.link)"> Link</i>
                  <!-- <a href="{{item.link}}" (click)="copyText">
                  Link
                </a> -->
              </td>
                <td>{{item.clicks}}</td>
                <td>{{item.conversions}}</td>
                <td><button class="btn btn-primary" (click)="view(item.affiliateId)">View</button></td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr>
                  <td [attr.colspan]="7" [ngStyle]="{'text-align': 'center'}">
                      No records found
                  </td>
              </tr>
          </ng-template>
          </p-table>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
  <div class="col-sm-3  py-2 px-2">
    <app-flash-page></app-flash-page>
  </div>
</div>

<ng-template #affiliationDetails>
  <div class="row m-2">
    <div class="col-sm-12 p-0">
      <!-- <div class="card p-4"> -->
        <div class="row pl-3 pr-3 pb-3">
          <div class="col-sm-8 p-0">
            <h5>View Affiliations</h5>
          </div>
          <div class="col-sm-4 p-0 aff-text">
            <i class="fa fa-times fa-close pointer" aria-hidden="true" (click)="dialog.closeAll();"></i>
            <!-- <span>Total Amount:</span> {{affiliateDetails.affiliate_amount}} -->
          </div>
        </div>
        <p-table #dt2 [value]="affiliateDetails.details" responsiveLayout="scroll" [rowHover]="true" [rows]="3"
          [showCurrentPageReport]="true" [rowsPerPageOptions]="[3,10,25,50]"
          styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['type_name','order_id','settlement_status','commission', 'ordered_date']">
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                  placeholder="Search " />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th>Sl.No</th>
              <th>Order Id</th>
              <th>Ordered On</th>
              <th>Type</th>
              <th>Affiliate Amount</th>
              <th>Status</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td> {{getIndex(item)+1}}</td>
              <td>{{item.order_id}}</td>
              <td>{{item.ordered_date}}</td>
              <td>{{item.type_name}}</td>
              <td>{{item.commission}}</td>
              <td>{{item.settlement_status}}</td>
            </tr>
          </ng-template>
        </p-table>

        <!-- <table class="table table-striped">
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Order Id</th>
                <th>Ordered On</th>
                <th>Type</th>
                <th>Affiliate Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of affiliateDetails.details; let i=index">
                  <td>{{i+1}}</td>
                  <td>{{item.order_id}}</td>
                  <td>{{item.ordered_date}}</td>
                  <td>{{item.type_name}}</td>
                  <td>{{item.commission}}</td>
                  <td>{{item.settlement_status}}</td>
              </tr>
            </tbody>
            <tbody  *ngIf=" affiliateDetails.details.length === 0">
              <tr>
                <td colspan="6" class="d-td">
                  No Records Found
                </td>
              </tr>
            </tbody>
        </table> -->

        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</ng-template>
<app-footer></app-footer>
