export class Brands{
    manufacturer_id: string;
    name: string;
    
}
export class AttributeDetails{
    attribute_group_id: number;
    attr_grp_name: string;

}
export class BrandDetails{

    brandDetails: Brands[];
}
