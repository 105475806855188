<div class="col-sm-12 card p-0">
    <div class="row m-0  pt-2 pb-2">
        <div class="col-12 d-flex text-center">
            <p class="userName-text">{{frstName |titlecase}} {{lastName |titlecase}}</p>
        </div>
    </div>
</div>


<div class="col-sm-12 card p-0">
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/profile'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/user.png" class="mr-1"> -->
            <i class="fa fa-user-circle-o  mr-1" aria-hidden="true"></i>
            &nbsp;<span> Personal Info</span>
        </p>
    </div>
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/myorders'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/orders.png" class="mr-1"> -->
            <i class="fa fa-cubes  mr-1" aria-hidden="true"></i>
           <span class="pl-1">Orders & Returns</span>
        </p>
    </div>
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/wishlist'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/heart.png" class="mr-1"> -->
            <i class="fa fa-heart-o mr-1" aria-hidden="true"></i>
            &nbsp;<span>Wish List</span>
        </p>
    </div>
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/manageaddresses'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/address.png" class="mr-1"> -->
            <i class="fa fa-map-marker  mr-1" aria-hidden="true"></i>
            &nbsp;<span>Manage Address</span>
        </p>
    </div>
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/savelater'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/coupon.png" class="mr-1"> -->
            <i class="fa fa-credit-card-alt mr-1" aria-hidden="true"></i>
            &nbsp;<span>Coupons</span>
        </p>
    </div>
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/MyVocherDetails'" routerLinkActive= "active" >
            <!-- <img src="assets/images/account/gift-card.png" class="mr-1"> -->
            <i class="fa fa-gift  mr-1" aria-hidden="true"></i>
            &nbsp;<span>Gift Cards</span>
        </p>
    </div>
 
    <div class="col-sm-12 p-3 menu-option" >
        <p class="header-name menu-option" [routerLink] = "'/affiliate'" routerLinkActive= "active" >
            <i class="fas fa-tags mr-1" aria-hidden="true"></i>
            &nbsp;<span>Affiliations</span>
        </p>
    </div>
   
    <!-- <div class="col-sm-12 p-3" >
        <p class="header-name mb-2">
            <i class="fas fa-user mr-2 menu-icon"></i>
            &nbsp;Account Settings</p>
        
        <mat-selection-list class= "account-list" [multiple]="false">
            <mat-list-option value= "personal" [routerLink] = "'/profile'" routerLinkActive= "active" > Personal Information </mat-list-option>
            <mat-list-option value= "addresses" [routerLink] = "'/manageaddresses'" routerLinkActive= "active"> Manage Addresses </mat-list-option>
            <mat-list-option value= "reviews&ratins" (click)="noActionFound()"> Reviews & Ratings </mat-list-option>
            <mat-list-option value= "notifications" (click)="noActionFound()"> Notifications </mat-list-option>
            <mat-list-option value= "wishlist"  [routerLink] = "'/wishlist'" routerLinkActive= "active"> Wish List </mat-list-option>
            <mat-list-option value= "wishlist"  (click) = "myvocherDetails()" routerLinkActive= "active"> Voucher Details </mat-list-option>
            <mat-list-option value= "wishlist"  (click) = "VocherDetails()" routerLinkActive= "active"> Gifted Vochers </mat-list-option>
        </mat-selection-list>
    </div> -->
</div>
