<div class="row card m-0 p-row">
  <div class="col-lg-12 col-sm-12 text-center">
      <p class="personal-info">
         Shipping Status
      </p>
  </div>
</div>
<div class="col-sm-12 p-0 " *ngIf="ord.data.length > 0">
  <div class="card p-0 ">
      <div class="card-body">
          <div class="col-sm-12">
          <p class="frstName-txt">Order ID: {{ord.data[0].orderId}}</p>
          <p class="status">Status</p>
          <p class="person-info">{{ord.data[0].status}}</p>
          </div>
          
      </div>
      <div class="col-sm-12 p-0" *ngIf="ord.data.length > 1">

          <mat-accordion>
              <mat-expansion-panel (opened)="panelOpenState = true"
                                   (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                  Latest Orders ({{ord.data.length - 1}})
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="col-sm-12 " *ngFor="let item of ord.data">
                  <p class="frstName-txt">Order ID: {{item.orderId}}</p>
                  <p class="status">Status</p>
                  <p class="person-info">{{item.status}}</p><br><br>
                  </div>
              </mat-expansion-panel>
            </mat-accordion>
      </div>
  </div>
</div><br>
<div class="row card m-0 p-row">
<div class="col-lg-12 col-sm-12 text-center">
    <p class="personal-info">
       Default Address
    </p>
</div>
</div>

<div class="col-sm-12 p-0">
<div class="card p-0 ">
    <div class="card-body">
      <div class="col-12 text-center"  *ngIf="addressDetailsArray.length === 0">
        <p class="cartEmptyText">No Address Found As Default</p>
      </div>
    
      <div class="col-sm-12" *ngIf="addressDetailsArray.length > 0">
        <p class="frstName-txt"><span>{{addressDetailsArray[0].firstname  |titlecase}} {{addressDetailsArray[0].lastname}}</span> &nbsp;
          <span class="badge badge-secondary add-type-badge"  *ngIf="addressDetailsArray[0].address_type !== undefinded && addressDetailsArray[0].address_type !== null && addressDetailsArray[0].address_type !== '' "> {{addressDetailsArray[0].address_type |titlecase}}</span>
        </p>
        <p class="person-info">{{addressDetailsArray[0].address_1}} {{addressDetailsArray[0].city |titlecase}}, </p>
        <p class="person-info"> {{addressDetailsArray[0].zone_name |titlecase}} {{addressDetailsArray[0].postcode}},
            {{addressDetailsArray[0].country_name |titlecase}} </p>
        <p class="person-info">Ph: {{addressDetailsArray[0].mobile}}</p>
    </div>
     
    </div>
</div>
</div>