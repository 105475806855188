// export class Wishlist {
//     ProductID: number;
//     SKU: string;
//     Name: string;
//     ProductURL: string;
//     Price: number;
//     RetailPrice: number;
//     ThumbnailURL: string;
//     SearchKeywords: string;
//     Description: string;
//     Category: string;
//     CategoryID: any;
//     Brand: string;
//     ChildSKU: string;
//     ChildPrice: string;
//     Color: string;
//     ColorFamily: string;
//     ColorSwatches: string;
//     Size: string;
//     ShoeSize: string;
//     PantsSize: string;
//     Occassion: string;
//     Season: string;
//     Badges: string;
//     RatingAvg: number;
//     RatingCount: number;
//     InventoryCount: number;
//     DateCreated: string;
// }

// export class WishlistObject {
//     wishlist: Wishlist[];
// }

export class Wishlist {
    customerId: number;
    productId: number;
    wishlistId: number;
    folderId: number;
    folderName: string;
    image: string;
    productName: string;
    model: string;
    stockStatusId: number;
    stock: string;
    price: number;
    specialPrice: number;
    discountPrice:number;
    offPrice:number;
    rating: number;
    prodId : number;
    currencyIndicator: string;
    ratingCount :number;
    reviewCount :number;
    averageRating:number;
}

export class wish {
    wishlists: Wishlist[];
}