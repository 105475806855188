<!-- <div class="col-sm-10 p-0 mt-3 ml-5"> -->
<div class="row mt-3 ml-5 mr-5">
  <div class="col-sm-2 py-2 px-2">
    <app-account></app-account>
  </div>
  <div class="col-sm-7 px-2 py-2">
    <!-- <div class="row card m-0">
                    <div class="col-sm-12 p-0">
                        <p class="personal-info">
                            Personal Information
                        </p>
                    </div>
                </div> -->
    <div class="row card m-0 p-row">
      <div class="col-9 p-0">
        <p class="personal-info">Personal Information</p>
      </div>
      <div class="col-3 p-3">
        <span *ngIf="showdisablename">
          <i
            class="fa fa-user-edit float-right pointer font-16 editiconcolor"
            matTooltip="Edit Information"
            (click)="is_edit = !is_edit; editPersonalInfo('show')"
          ></i>
        </span>
      </div>
    </div>

    <div class="col-sm-12 p-0">
      <div class="card p-0">
        <div class="card-body p-0 row m-0">
          <div class="col-12 p-5">
            <!-- <div class="form-group row">
                            <label for="staticEmail" class="col-sm-2 col-form-label p-form-label required p-0">First Name</label> -->
            <div class="col-sm-12 pr-0">
              <mat-form-field class="col-12 pl-0" appearance="outline">
                <mat-label>First Name</mat-label>
                <input
                  matInput
                  placeholder="First Name"
                  [(ngModel)]="firstName"
                  class="profile-inp"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                  [disabled]="is_edit"
                  required
                />
              </mat-form-field>
              <!-- <input type="text" class="form-control  p-form-input" [(ngModel)]="firstName" placeholder="First Name"
                                    [ngClass]="{'disabledcursor' : is_edit === true}" [disabled]="is_edit" required >  
                                    <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="reqField">
                                        First Name is required.
                                    </div>  -->
            </div>
            <!-- </div> -->
            <!-- <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label p-form-label required p-0">Last Name</label> -->
            <div class="col-sm-12 pr-0">
              <!-- <input type="text" class="form-control  p-form-input" placeholder="Last Name"
                                    [(ngModel)]="lastName" [ngClass]="{'disabledcursor' : is_edit === true}"
                                    [disabled]="is_edit"> -->
              <mat-form-field class="col-12 pl-0" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input
                  matInput
                  placeholder="Last Name"
                  [(ngModel)]="lastName"
                  class="profile-inp"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                  [disabled]="is_edit"
                  appearance="fill"
                  required
                />
              </mat-form-field>
            </div>
            <!-- </div> -->
            <!-- <div class="form-group row"> -->
            <!-- <label for="inputPassword" class="col-sm-2 col-form-label p-form-label required p-0">Gender</label> -->
            <div class="col-sm-12 pr-0 pl-4">
              <mat-radio-group
                aria-label="Select an option"
                class="genderRadio"
                [(ngModel)]="gender"
              >
                <!-- <label for="inputPassword" class="col-sm-2 col-form-label p-form-label required p-0">Gender</label> -->
                <mat-label
                  [disabled]="is_edit"
                  [ngClass]="{ 'disabledcursor-lable ': is_edit === true }"
                  class="requiredcls"
                  >Gender</mat-label
                ><br />
                <mat-radio-button
                  value="male"
                  [disabled]="is_edit"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                >
                  Male
                </mat-radio-button>
                <mat-radio-button
                  value="female"
                  class="ml-4"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                  [disabled]="is_edit"
                  >Female
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <!-- </div> -->
            <!-- <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label p-form-label required p-0">Mobile
                                Number</label> -->
            <div class="col-sm-12 pr-0">
              <mat-form-field class="col-12 pl-0" appearance="outline">
                <mat-label>Mobile</mat-label>
                <input
                  matInput
                  placeholder="Mobile Number"
                  [(ngModel)]="mobile"
                  class="profile-inp"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                  [disabled]="is_edit"
                  required
                />
              </mat-form-field>
              <!-- <input type="text" class="form-control p-form-input" placeholder="Mobile Number"
                                    [(ngModel)]="mobile" [ngClass]="{'disabledcursor' : is_edit === true}"
                                    [disabled]="is_edit"> -->
            </div>
            <!-- </div> -->
            <!-- <div class="form-group row">
                            <label for="inputPassword" class="col-sm-2 col-form-label p-form-label required p-0">Email</label> -->
            <div class="col-sm-12 pr-0">
              <!-- <input type="text" class="form-control  p-form-input" placeholder="Email"
                                    [(ngModel)]="email" [ngClass]="{'disabledcursor' : is_edit === true}"
                                    [disabled]="is_edit"> -->
              <mat-form-field class="col-12 pl-0" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  placeholder="Email Address"
                  [(ngModel)]="email"
                  class="profile-inp"
                  [ngClass]="{ disabledcursor: is_edit === true }"
                  [disabled]="is_edit"
                  required
                />
              </mat-form-field>
            </div>
            <!-- </div> -->

            <!-- <div class="row m-0">
                                    <div class="col-12 pt-3">
                                        <div class="row m-0 p-2 ml-2">
                                            <div class="col-6 p-0">
                                                <mat-form-field class="col-10 pl-0">
                                                    <input matInput placeholder="First Name" [(ngModel)]="firstName"
                                                        [disabled]="is_edit" appearance="fill" class="disabledcursor">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-6 p-0">
                                                <mat-form-field class="col-10 pl-0">
                                                    <input matInput placeholder="Last Name" [(ngModel)]="lastName"
                                                        [disabled]="is_edit" appearance="fill" class="disabledcursor">
                                                </mat-form-field>
                                            </div>
                                        </div>
        
                                        <div class="row m-0 ml-3 font-14">Your Gender</div>
                                        <div class="row m-0 p-2 ml-2 mt-3">
        
                                            <div class="col-6 p-0">
                                                <mat-radio-group aria-label="Select an option" class="genderRadio"
                                                    [(ngModel)]="gender">
                                                    <mat-radio-button value="male" [disabled]="is_edit" class="disabledcursor">
                                                        Male
                                                    </mat-radio-button>
                                                    <mat-radio-button value="female" class="ml-4 disabledcursor"
                                                        [disabled]="is_edit">Female
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>
        
                                        <div class="row m-0 mt-4">
                                            <p class="personal-info ml-3">
                                                <span>
                                                    Email Address
                                                </span>
        
                                            </p>
        
                                        </div>
                                        <div class="row m-0 p-3">
                                            <div class="col-6 p-0">
                                                <mat-form-field class="col-10 pl-0">
                                                    <input matInput placeholder="Email Address" [(ngModel)]="email"
                                                        [disabled]="is_edit" class="disabledcursor">
                                                </mat-form-field>
                                            </div>
        
                                        </div>
        
                                        <div class="row m-0 ">
                                            <p class="personal-info ml-3">
                                                <span>
                                                    Mobile Number
                                                </span>
        
                                            </p>
        
                                        </div>
                                        <div class="row m-0 p-3">
                                            <div class="col-6 p-0">
                                                <mat-form-field class="col-10 pl-0">
                                                    <input matInput placeholder="Mobile Number" [(ngModel)]="mobile"
                                                        [disabled]="is_edit" class="disabledcursor">
                                                </mat-form-field>
                                            </div>
        
                                        </div> -->
            <!-- <div class="row m-0 pt-4" *ngIf="showeditablename">
                                            <div class="col-3 pt-2 text-center">
                                                <p class="addCart pointer" (click)="profilesave()">Save</p>
                                            </div>
                                            <div class="col-3 pt-2 text-center"
                                                (click)="is_edit = !is_edit;editPersonalInfo('hide');">
                                                <p class="addCart pointer">cancel</p>
                                            </div>
                                        </div> -->
            <div class="row m-0 mt-3">
              <div class="col-sm-5 p-0">
                <a class="pb-3 password pointer" (click)="showpasswords()">
                  Change password
                </a>
              </div>
              <div class="col-sm-7 text-right" *ngIf="showeditablename">
                <button
                  type="button"
                  class="btn btn-warning update-btn"
                  (click)="profilesave()"
                >
                  UPDATE
                </button>
                &nbsp;
                <button
                  type="button"
                  class="cancel-btn"
                  (click)="is_edit = !is_edit; editPersonalInfo('hide')"
                >
                  CANCEL
                </button>
              </div>
            </div>
            <div class="row m-0 mt-4" *ngIf="showpassword">
              <div class="col-4 p-0">
                <mat-form-field class="col-10 pl-0">
                  <input
                    matInput
                    [type]="oldhide ? 'password' : 'text'"
                    placeholder="Current password"
                    [(ngModel)]="old_password"
                    type="password"
                    required
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="oldhide = !oldhide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      oldhide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </div>

              <div class="col-4 p-0">
                <mat-form-field class="col-10 pl-0">
                  <input
                    matInput
                    [type]="newhide ? 'password' : 'text'"
                    placeholder="New Password"
                    [(ngModel)]="new_password"
                    type="password"
                    required
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="newhide = !newhide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      newhide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-4 p-0">
                <mat-form-field class="col-10 pl-0">
                  <input
                    matInput
                    [type]="confirmhide ? 'password' : 'text'"
                    placeholder="Confirm Password"
                    [(ngModel)]="retype_password"
                    type="password"
                    required
                  />
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="confirmhide = !confirmhide"
                    [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{
                      confirmhide ? "visibility_off" : "visibility"
                    }}</mat-icon>
                  </button>
                </mat-form-field>
              </div>
              <div class="col-6 p-0">
                <button
                  class="mt-2 btn btn-primary addCart pointer"
                  (click)="changepwd()"
                >
                  Save</button
                >&nbsp;
                <button
                  type="button"
                  class="btn btn-warning mt-2 cancel-btn"
                  (click)="showpassword = !showpassword"
                >
                  CANCEL
                </button>
              </div>
            </div>

            <!-- </div>
                                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-3 py-2 px-2">
    <app-flash-page></app-flash-page>
  </div>
</div>
<app-footer></app-footer>
<!-- </div> -->
