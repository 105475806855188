<div class="row mt-3 ml-5 mr-5">
    <div class="col-sm-2 py-2 px-2">
        <app-account></app-account>
    </div>
    <div class="col-sm-7 px-2 py-2">
        <ngx-spinner class="spinnerCss">
            <p class="spinnerText">Loading....</p>
        </ngx-spinner>
        <div class="row card m-0 p-row">
            <div class="col-lg-12 col-sm-12 p-0">
                <p class="personal-info">
                    Coupons Information
                </p>
            </div>
        </div>
        <div class="col-sm-12 p-0 detaldiv1">
            <div class="card p-0 ">
                <div class=" row m-0 text-center" *ngIf="couponsArray.length === 0">
                    <div class="col-sm-6 offset-sm-3 mt-3 mb-3">
                        <img src="{{imgurl}}noImag.png" alt="" class="img-fluid d-block pl-2 pr-0 cartEmptyText">
                        <h5 class=" m-2 p-2 cartEmptyText">No coupons found</h5>
                    </div>
                </div>
            </div>
            <div class="card p-0 " *ngFor="let CouponsList of couponsArray">
                <div class="card-body">
                    <div class="row m-0">
                        <div class="col-sm-12 col-lg-2 col-md-2 imgaligin p-0 pr-4">
                            <img src="{{imgurl}}images.png" class="oreredImg" />
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12   dashed-col pr-0">
                            <p class="mb-1 prodName">{{CouponsList.name |titlecase}} Offer</p>
                            <p class="coupDes">Get Exclusive deals on {{CouponsList.category_name}} by using
                                {{CouponsList.name |titlecase}} Coupon</p>
                            <p class="tillDate mt-4"> Valid until: {{CouponsList.date_end.split(' ')[0]}} </p>
                            <!-- <p class="mb-1">Use Promo Code: <b> {{CouponsList.code}}</b></p>
                            <p class="mb-1">Discount: {{CouponsList.discount}}</p> -->
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-12 orderStatusDiv text-center">
                            <p *ngIf="CouponsList.type === 'F'"><span
                                    class="offPrice">{{CouponsList.currency_indicator}}{{CouponsList.discount}}</span>&nbsp;<span
                                    class="offText">OFF</span></p>
                            <p *ngIf="CouponsList.type === 'P'"><span
                                    class="offPrice">{{CouponsList.discount}}{{CouponsList.type_symbol}}</span>
                                &nbsp;<span class="offText">OFF</span></p>
                            <p class="promo">Promo Code</p>
                            <p> <button type="button" class="btn  coup-code-btn">{{CouponsList.code}}</button></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-sm-3  py-2 px-2">
        <app-flash-page></app-flash-page>
    </div>
</div>
<app-footer></app-footer>