<footer id="footer">
  <div class="row m-0">
    <div class="col-sm-3" *ngFor="let section of tenantFooterData.data">
      <h5 class="footer-heading">{{ section.title }}</h5>
      <div>
        <p *ngFor="let item of section.subtitle">
          <ng-container
            *ngIf="section.title === 'About' && orgName === 'Veterneo'"
          >
            <a
              class="footer-links"
              [href]="getHrefLink(item)"
              target="_blank"
              >{{ item }}</a
            >
          </ng-container>
          <span
            *ngIf="section.title !== 'About' || orgName !== 'Veterneo'"
            class="footer-links"
            >{{ item }}</span
          >
        </p>
      </div>
    </div>

    <ng-template>
      <!-- <div class="col-sm-1">
            <img src="{{imgurl}}cswg.png" class="bottomLogo">
        </div> -->
      <div class="col-sm-3">
        <h5 class="footer-heading">ABOUT</h5>
        <div class="d-flex flex-column">
          <a
            class="footer-links"
            href="https://www.veterneo.com/"
            target="_blank"
          >
            Home
          </a>
          <a
            class="footer-links"
            href="https://www.veterneo.com/contact/"
            target="_blank"
            >Contact Us</a
          >
          <a
            class="footer-links"
            href="https://www.veterneo.com/about/"
            target="_blank"
            >About Us</a
          >
          <!-- <p class="footer-links">Careers</p>
        <p class="footer-links">News</p> -->
          <a
            class="footer-links"
            href="https://www.veterneo.com/business-enquiry/"
            target="_blank"
            >Bussiness Equirey</a
          >
        </div>
      </div>
      <div class="col-sm-3">
        <h5 class="footer-heading">NUTRITION & SUPPLEMENTS</h5>
        <p class="footer-links">Calisupp</p>
        <p class="footer-links">Multisupp</p>
        <p class="footer-links">Liversupp</p>
        <p class="footer-links">Gronic</p>
        <p class="footer-links">N-Mix Forte</p>
      </div>

      <!-- <div class="col-sm-3" *ngFor="let data of tenantFooterData">
      <h5 class="footer-heading">{{data.title}}</h5>
      <p class="footer-links">{{data.subtitle}}</p>
      <p class="footer-links">Multisupp</p>
      <p class="footer-links">Liversupp</p>
      <p class="footer-links">Gronic</p>
      <p class="footer-links">N-Mix Forte</p>
    </div> -->

      <!-- <div class="col-sm-3">
      <h5 class="footer-heading">Help</h5>
      <p class="footer-links">Payments</p>
      <p class="footer-links">Shipping</p>
      <p class="footer-links">Cancellation & Returns</p>
      <p class="footer-links">FAQ’s</p>
      <p class="footer-links">Reports</p>
      <p class="footer-links">Refer-a-friend</p>
      <p class="footer-links">Shining a Light</p>
    </div>
    <div class="col-sm-3">
      <h5 class="footer-heading">Legal</h5>
      <p class="footer-links">General Info</p>
      <p class="footer-links">Privacy Policy</p>
      <p class="footer-links">Terms of Service</p>
    </div> -->
      <div class="col-sm-3">
        <h5 class="footer-heading">DEWORMERS & ANTIBIOTICS</h5>
        <p class="footer-links">N-Triclamet</p>
        <p class="footer-links">Ciflo-TZ</p>
        <p class="footer-links">Vetazit</p>
        <p class="footer-links">Enrobex</p>
        <p class="footer-links">En-Clear</p>
        <p class="footer-links">Sheegra</p>
      </div>
      <div class="col-sm-3">
        <h5 class="footer-heading">Address & Location</h5>
        <!-- {{this.storeDetailsArray.length |json}} -->
        <div *ngIf="storeDetailsArray.length > 0 && storeId !== 'all'">
          <p class="footer-links">
            <span>{{ this.storeDetailsArray[0].address }}</span
            >&nbsp;<span>{{ this.storeDetailsArray[0].address_city }}</span>
          </p>
          <!-- <p class="footer-links">{{this.storeDetailsArray[0].address_line1}}</p> -->
          <p class="footer-links">
            <span>{{ this.storeDetailsArray[0].location }}</span
            >&nbsp;<span>{{ this.storeDetailsArray[0].geocode }}</span>
          </p>
          <p class="footer-links">
            {{ this.storeDetailsArray[0].country }}
          </p>
          <p class="footer-links">{{ this.storeDetailsArray[0].email }}</p>
          <p class="footer-links">{{ this.storeDetailsArray[0].telephone }}</p>
        </div>
        <div *ngIf="storeDetailsArray.length === 0 || storeId === 'all'">
          <p class="footer-links">{{ tenantAddress.phoneNum }}</p>
          <p class="footer-links">{{ tenantAddress.address4 }}</p>
          <p class="footer-links">
            <span>{{ tenantAddress.address5 }}</span>
          </p>
          <p class="footer-links">{{ tenantAddress.address6 }}</p>
          <p class="footer-links">{{ tenantAddress.email }}</p>
          <!-- <p>{{this.storeDetailsArray[0].telephone}}</p> -->
        </div>

        <!-- <p><span class="ml-2 pointer font-14 fw-200">
                    <i class="fa fa-share-alt  font-25 iconColor" aria-hidden="true" (click)="share()"></i> <span
                        class="font-20 iconColor"> Share</span>
                </span>
            </p> -->
      </div>
    </ng-template>
  </div>
  <hr />
  <div class="row d-flex justify-content-center align-items-center mt-5">
    <div class="col-lg-4 col-md-4 col-sm-5">
      <span *ngIf="storeId == null || storeId === 'all'">
        <img
          [src]="mainLogo"
          alt=""
          class="pointer logo-img"
          [routerLink]="'/home'"
        />
      </span>
      <span
        class="pointer"
        *ngIf="
          storeId !== 'all' &&
          (logo == null || logo == undefined || logo === '')
        "
        [routerLink]="'/home'"
      >
        <h1 class="logo-font">{{ storeName }}</h1>
      </span>
      <span
        class="pointer"
        *ngIf="
          storeId !== 'all' &&
          storeId !== '0' &&
          logo !== null &&
          logo !== undefined &&
          logo !== ''
        "
      >
        <img
          [src]="logo"
          alt=""
          class="pointer main-img"
          [routerLink]="'/home'"
        />
      </span>

      <!-- <img src="{{imgurl}}cswg.png" alt="" class="pointer logo-img" [routerLink]="'/home'"> -->
    </div>
    <div class="col-lg-4 col-md-4 col-sm-3">
      <p class="text-center footer-text">
        © 2023 {{ orgName | titlecase }}. All Rights Reserved.
      </p>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4">
      <div class="example-button-container text-center">
        <div *ngIf="orgName === 'Veterneo'">
          <a href="https://www.facebook.com/veterneo" target="_blank">
            <button
              mat-mini-fab
              color="primary"
              class="bg-fb"
              matTooltip="Facebook"
            >
              <i class="fa fa-facebook" aria-hidden="true"></i></button></a
          >&nbsp;&nbsp;
          <a href="https://www.instagram.com/veterneo/" target="_blank">
            <button
              mat-mini-fab
              color="primary"
              class="bg-insta"
              matTooltip="Instagram"
            >
              <i class="fa fa-instagram" aria-hidden="true"></i>
            </button> </a
          >&nbsp;&nbsp;
          <a href="https://twitter.com/Veterneo1" target="_blank">
            <button
              mat-mini-fab
              color="primary"
              class="bg-twitter"
              matTooltip="Twitter"
            >
              <i class="fa fa-twitter" aria-hidden="true"></i>
            </button> </a
          >&nbsp;&nbsp;
        </div>
        <div *ngIf="orgName !== 'Veterneo'">
          <button mat-mini-fab color="primary" class="bg-fb">
            <i class="fa fa-facebook" aria-hidden="true"></i></button
          >&nbsp;&nbsp;
          <button mat-mini-fab color="primary" class="bg-insta">
            <i class="fa fa-instagram" aria-hidden="true"></i></button
          >&nbsp;&nbsp;
          <button mat-mini-fab color="primary" class="bg-twitter">
            <i class="fa fa-twitter" aria-hidden="true"></i></button
          >&nbsp;&nbsp;
          <button mat-mini-fab color="primary" class="bg-ln">
            <i class="fa fa-linkedin" aria-hidden="true"></i>
          </button>
          &nbsp;&nbsp;
          <button
            mat-mini-fab
            color="primary"
            class="bg-google"
            matTooltip="Share this site with your friends"
          >
            <i
              class="fa fa-share-alt font-18"
              aria-hidden="true"
              (click)="share()"
            ></i>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="col-sm-12 bottomDiv">
            <p class="mt2s" style="margin-left: 40%;"> Powered by </p>
            <span>
                <b>
                    <p class="mt2s" style="float: left; color: rgb(255 127 39); "> BOT</p>
                </b> </span>
            <span>
                <b>
                    <p class="mt2s" style="float: left; color: #fe8824;">COM </p>
                </b>
            </span>
            <span>
                <p class="mt2s"> All Rights Reserved.</p>
            </span>
        </div> -->
  </div>
</footer>

<ng-template let-bottomSheetRef="bottomSheetRef" #shareDetails>
  <div class="row m-2">
    <!-- <div class="col-sm-2 pr-5">
        <img [src]="productMainImage" class="product-image" width="100" height="50">
      </div> -->
    <div class="col-sm-12 text-right">
      <i
        class="fa fa-times fa-close pointer"
        aria-hidden="true"
        (click)="bottomSheet.dismiss()"
      ></i>
    </div>
    <div class="col-sm-12 p-5 d-col">
      <div class="row">
        <strong>{{ orgName }}</strong>
      </div>
      <div class="row">
        <div class="col-sm-10 p-0">
          <p>
            Here's the link <a>{{ url }}</a>
          </p>
        </div>
        <div class="col-sm-2">
          <i
            class="fa fa-clone d-ico"
            aria-hidden="true"
            (click)="copyText(url)"
          ></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>
