import {
  EventEmitter,
  OnChanges,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  Component,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../views/services/shared.service';
import {
  CategoryDetail,
  MenuData,
  MenuObject,
  ChildCategoryDetail,
} from './modal/menu-modal';
import { MenuService } from './service/menu.service';
import { environment } from 'src/environments/environment.prod';
import { CommonService } from '../helper/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilsService } from '../helper/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { CookieService } from 'ngx-cookie-service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';
import { LandingPageService } from '../landing-page/service/landing-page.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as uuid from 'uuid';
import { data } from 'jquery';
import { TenantBasedDataService } from '../shared/services/tenant-based-data.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayoutComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('shareDetails') ShareDetails: TemplateRef<any>;
  @ViewChild('affiliationDetails') invite: TemplateRef<any>;
  @ViewChild('categoryDetails') CategoryDetails: TemplateRef<any>;

  menu = [];
  myMenuList = [];
  count;
  menuObj: MenuObject = new MenuObject();
  imgurl;
  imgurl1;

  totCartCount;
  totWishCount;
  username;
  sessionIdLoged;
  logedEmailId;
  searchedKey = '';
  logedCustomerId;
  loggingWithGmailFlag;
  valuedeclare;
  url;
  currentURL;
  storeList = [];
  currencyDropList = [];
  storeName: string;
  logo: any;
  storeId;
  storeDetailsArray;
  searched;
  currencyName: string;
  dropFlag: boolean;
  defaultCurrencyName: string;
  profileDw = true;
  loginBtnUp = false;
  guestSessionId;
  tenantId;
  value = false;
  value1;
  toggleflag: boolean;
  toggleflag2: boolean;
  // botView : boolean;
  botView = false;
  createform: FormGroup;
  currentRate = 4.5;
  changeText = false;
  showIndex;
  activeMenu = false;
  @Output() categoryId = new EventEmitter();
  logedSeeionId: any;
  categoryList = [];
  frstName: string;
  lastName: string;
  parentId: any;
  botEnable: any;
  isMobileLogin: boolean;
  deviceUID;
  searchFlag = false;
  company: any;
  mainLogo: any;
  phoneNum: any;
  tabselected: any;
  tabUnSelected: any;
  traditionSelected: any;
  traditionUnSelected: any;
  orgName: any;
  constructor(
    private ms: MenuService,
    private router: Router,
    private shared: SharedService,
    private LPService: LandingPageService,
    private common: CommonService,
    private cookieService: CookieService,
    private snack: MatSnackBar,
    private utils: UtilsService,
    private spinner: NgxSpinnerService,
    readonly bottomSheet: MatBottomSheet,
    private clipboard: Clipboard,
    private deviceService: DeviceDetectorService,
    readonly dialog: MatDialog,
    public formBuilder: FormBuilder,
    private tenantDataService: TenantBasedDataService
  ) {
    this.genarateId();
    this.getOrgId();
    // setTimeout(() => {

    // }, 500);

    const token = sessionStorage.getItem('token');
    this.username = sessionStorage.getItem('userdata');
    this.lastName = sessionStorage.getItem('lastname');
    this.frstName = sessionStorage.getItem('firstname');
    this.guestSessionId = this.cookieService.get('GuestSessionIDdata');
    this.logedSeeionId = sessionStorage.getItem('sessionId');
    this.username = sessionStorage.getItem('userdata');
    this.sessionIdLoged = sessionStorage.getItem('sessionId');
    this.logedEmailId = sessionStorage.getItem('userEmail');
    this.deviceUID = localStorage.getItem('UUID');
    this.tenantId = sessionStorage.getItem('tenantId');
    // if (
    //   this.tenantId !== undefined ||
    //   this.tenantId !== null ||
    //   this.tenantId !== ''
    // ) {
    //   this.getCategories(this.tenantId);
    // }

    this.epicFunction();
    // this.testing()

    console.log('GuestSessionIDdataappcomp..', this.guestSessionId);
    if (
      (token === null || token === undefined) &&
      (this.username === null || this.username === undefined) &&
      (this.guestSessionId === null || this.guestSessionId === undefined)
    ) {
      console.log('refresh');
      this.router.navigate(['/home']);
    } else {
      this.utils.decryptJWT();
    }
    this.imgurl = environment.imageURL;
    this.imgurl1 = environment.imageSvgURL;
    this.common.searchKeyEvent.subscribe((data) => {
      console.log(
        'Event message from Component  searchhhh common service: ',
        data
      );
      this.searchedKey = '';
    });
  }

  ngOnInit(): void {
    console.log('navigator.userAgent;', navigator.userAgent);
    this.createform = this.formBuilder.group({
      name: ['', Validators.required],
      mail: ['', Validators.required],
      num: ['', Validators.required],
      note: [''],
    });
    console.log('bot view ', this.botView);
    this.toggleflag = false;
    this.toggleflag2 = true;
    this.currentURL = window.location.href;
    console.log(this.currentURL);
    if (this.currentURL.includes('USID')) {
      var splitUrl = this.currentURL.split('=')[1];
      sessionStorage.setItem('StoreId', splitUrl);
    }
    this.currencyName = sessionStorage.getItem('currencyName');
    this.valuedeclare = sessionStorage.getItem('social');
    if (this.valuedeclare == 'social' && this.username == null) {
      this.sociallogin();
    }

    if (this.sessionIdLoged == null) {
      this.profileDw = false;
      this.loginBtnUp = true;
      console.log(this.sessionIdLoged);
      console.log('logintrue');
    } else {
      this.profileDw = true;
      this.loginBtnUp = false;
      console.log(this.sessionIdLoged);
      console.log('loginfalse');
    }

    this.common.profileEvent.subscribe((data: any) => {
      this.lastName = sessionStorage.getItem('lastname');
      this.frstName = sessionStorage.getItem('firstname');
    });

    this.common.aClickedEvent.subscribe((data: any) => {
      console.log('Event message from Component A: ' + data);
      this.storeId = sessionStorage.getItem('StoreId');
      this.currencyList();
      // this.getCategories();

      if (
        this.tenantId !== undefined ||
        this.tenantId !== null ||
        this.tenantId !== ''
      ) {
        this.getCategories(this.tenantId);
      }

      const currentURL = window.location.href;
      if (currentURL.includes('search')) {
        this.searchedKey = sessionStorage.getItem('searchedValue');
      }
    });
    this.common.currencyClickedEvent.subscribe((data: any) => {
      const currentURL = window.location.href;
      if (currentURL.includes('search')) {
        this.searchedKey = sessionStorage.getItem('searchedValue');
        this.handleSearch();
      }
    });
    this.common.botSearchEvnt.subscribe((data: any) => {
      const currentURL = window.location.href;
      console.log('layout compont search', data);
      this.searchedKey = data;
      this.handleSearch();
    });
    const botView = this.common.updatedToggle;
    console.log('botttt oninit', botView);
    this.storeId = sessionStorage.getItem('StoreId');
    this.storeName = sessionStorage.getItem('storeName');
    this.logo = sessionStorage.getItem('storeLogo');

    this.logedCustomerId = sessionStorage.getItem('customerId');
    this.loggingWithGmailFlag = sessionStorage.getItem('loggingWithGmailFlag');
    const info = this.utils.getUserInfo();
    const fName = info.firstName;
    // this.getCategories();

    this.viewCart();
    this.viewSettings();
    this.getTenantBasedLogo();

    this.ms.stringSubject.subscribe((data) => {
      this.totCartCount = data;
    });
    this.ms.wishListCount.subscribe((data) => {
      this.totWishCount = data;
    });

    // if (this.loggingWithGmailFlag === true){

    //   this.username = fName;
    // }
    // else{
    //   this.username = sessionStorage.getItem('userdata');
    //   this.sessionIdLoged = sessionStorage.getItem('sessionId');
    //   this.logedEmailId = sessionStorage.getItem('userEmail');
    //   this.logedCustomerId = sessionStorage.getItem('customerId');
    // }

    // Add by Devi for disable a button based on routing page
    this.botEnable = this.currentURL.split('#')[1];
    console.log('botenable', this.botEnable);

    if (this.username == null) {
      this.username = fName;
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    //console.log(change);
    // this.getCategories();
  }

  mouseEnter(text, idx) {
    // this.changeText = text;
    this.showIndex = idx;
    console.log(' this.changeText', this.changeText);
  }
  parentCatClick(obj, text) {
    console.log('category object', obj);
    this.categoryList = obj.categoryDetails;
    this.parentId = obj.parentId;
    this.activeMenu = !obj.active;
    console.log('ca', this.activeMenu);
    console.log('category categoryList', this.categoryList);
    console.log(' this.changeText', this.changeText);
  }

  getCategories(tenantId): any {
    let body;
    // if (this.currentURL.includes('USID'))
    //   {
    //     debugger
    //     body = { categorymenu_op_type: 'select', store_id: this.sdids };
    //   }
    // else{
    console.log('changed store id', this.storeId);
    if (this.storeId === null || this.storeId === 'all') {
      body = {
        categorymenu_op_type: 'select',
        // tenant_id: this.tenantId,
        tenant_id: tenantId,
        limit: 20,
      };
    } else {
      console.log('else condition store', this.storeId);
      // }
      // if (this.currentURL.includes('USID'))
      // {
      //   debugger
      //   body = { categorymenu_op_type: 'select', store_id: this.sdids };
      // }
      // else{
      body = {
        categorymenu_op_type: 'select',
        store_id: this.storeId,
        tenant_id: this.tenantId,
        limit: 20,
      };
      // }
    }
    console.log(body);
    this.ms.getMenu(body).subscribe(
      (data) => {
        if (data.res_status === true) {
          this.menuObj.menuData = Array<MenuData>();
          const dataset = data.data;
          console.log('res dataset', dataset);
          this.menuObj.menuData = [];
          dataset.map((item, idx) => {
            const imgObj = item.image;
            const image = JSON.parse(imgObj);
            if ('file_data' in image) {
              const imgArr = image.file_data;
              imgArr.map((ele) => {
                if (ele.storage_type === 'db') {
                  dataset[idx].imageUrl =
                    'data:image/png;base64,' + ele.media_data;
                  let selectedfile = 'data:image/png;base64,' + ele.media_data;
                  let imgId = ele.media_id;
                } else {
                  dataset[idx].imageUrl = ele.media_data;
                  let weekdealsAdss = ele.media_data;
                  let imgId = ele.media_id;
                }
              });
            } else {
              dataset[idx].imageUrl = image.data;
            }
            console.log('dataset', dataset);
            const menuDataSet = new MenuData();
            menuDataSet.parentId = item.parent_id;
            menuDataSet.parentName = item.parent_name;
            menuDataSet.active = false;
            menuDataSet.parentImg = item.imageUrl;
            menuDataSet.categoryDetails = Array<CategoryDetail>();
            const categoryData = item.child_details;
            categoryData.map((subItem) => {
              const categoryDataSet = new CategoryDetail();
              categoryDataSet.childId = subItem.child_id;
              categoryDataSet.childName = subItem.child_name;
              categoryDataSet.childParentId = subItem.parent_id;
              // categoryDataSet.childImg = item.imageUrl;
              menuDataSet.categoryDetails.push(categoryDataSet);
              // categoryDataSet.childCategoryDetail = Array<ChildCategoryDetail>();
              // const childCategoryData = subItem.child_details;
              // childCategoryData.map((child)=> {
              //   const subCategoryDataSet = new ChildCategoryDetail();
              //   subCategoryDataSet.subChildId = child.child_id;
              //   subCategoryDataSet.subChildName = child.child_name;
              //   categoryDataSet.childCategoryDetail.push(subCategoryDataSet);
              // })
            });

            this.menuObj.menuData.push(menuDataSet);
          });
          console.log('this.menuObj.menuData', this.menuObj.menuData);
          this.categoryList = [];
          this.parentId = '';
          this.categoryList = this.menuObj.menuData[0].categoryDetails;
          this.parentId = this.menuObj.menuData[0].parentId;

          // Adding view all in menu list
          // const menuDataSet = new MenuData();
          // menuDataSet.parentId = 0;
          // menuDataSet.parentName = 'View All';
          // menuDataSet.parentImg = 'assets/svg/View More.svg';
          // menuDataSet.categoryDetails = Array<CategoryDetail>();
          // this.menuObj.menuData.push(menuDataSet);
        } else {
          //console.log(data);
        }
      },
      (err) => {
        //console.log(err);
      }
    );
  }
  getTenantBasedLogo() {
    this.mainLogo = this.tenantDataService.Logo;
    this.phoneNum = this.tenantDataService.phoneNum;
    this.tabselected = this.tenantDataService.tabSelectedImg;
    this.tabUnSelected = this.tenantDataService.tabUnSelectedImg;
    this.traditionSelected = this.tenantDataService.traditionalSelectedImg;
    this.traditionUnSelected = this.tenantDataService.traditionalUnSelectedImg;
    this.orgName = this.tenantDataService.orgName;
  }

  goToProducts(id, name): void {
    console.log(id, name);
    document.querySelector('body').scrollTo(0, 0);
    this.categoryId.emit(id);
    this.router.navigate(['/product', id, name]);
    this.dialog.closeAll();
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 100); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  viewCart(): any {
    let body;
    if (this.sessionIdLoged === null) {
      body = {
        preview_op_type: 'view_cart',
        currency_id: 4,
        login: false,
        session_id: this.guestSessionId,
        tenant_id: this.tenantId,
        coupon_applied: false,
        voucher_applied: false,
      };
    } else {
      body = {
        preview_op_type: 'view_cart',
        user_name: this.logedEmailId,
        currency_id: 4,
        tenant_id: this.tenantId,
        login: true,
        session_id: this.sessionIdLoged,
        coupon_applied: false,
        voucher_applied: false,
      };
    }

    const success = this.ViewCartSuccess.bind(this);
    const error = this.onError.bind(this);
    this.common.http.post('Preview', body, success, error);
  }
  ViewCartSuccess(data): any {
    //console.log(this.totCartCount);
    if (data.res_status === true) {
      this.totCartCount = data.Data.cart_count;
      this.totWishCount = data.Data.wishlist_count;
    } else {
      this.totCartCount = data.Data.cart_count;
      this.totWishCount = data.Data.wishlist_count;
    }
  }
  onError(data) {}

  handleNoInfo(): any {
    //
    // this.snack.open(this.common.comingSoonText, 'Ok', { duration: 1000 });
    this.router.navigate(['/savelater']);
  }
  ngOnDestroy(): void {}

  handleLogOut() {
    //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['/auth/login']);
    // });
    const body = {
      session_id: this.sessionIdLoged,
      user_name: this.logedEmailId,
      tenant_id: this.tenantId,
    };
    const success = this.LogoutSuccess.bind(this);
    const error = this.onErrorLogout.bind(this);
    this.common.http.post('EcommLogout', body, success, error);
  }
  LogoutSuccess(data): any {
    if (data.res_status) {
      sessionStorage.removeItem('userdata');
      sessionStorage.removeItem('sessionId');
      sessionStorage.removeItem('userEmail');
      sessionStorage.removeItem('customerId');
      sessionStorage.removeItem('loggingWithGmailFlag');
      sessionStorage.removeItem('firstname');
      sessionStorage.removeItem('lastname');
      sessionStorage.removeItem('telephone');
      sessionStorage.removeItem('social');
      sessionStorage.removeItem('profileimagess');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('affiliateUrl');
      sessionStorage.removeItem('StoreId');
      // sessionStorage.removeItem('token'); rzp_device_id
      localStorage.removeItem('rzp_device_id');
      sessionStorage.removeItem('codeVerifier');
      // localStorage.removeItem('StoreId');
      // localStorage.removeItem('AffiliateUrl');
      this.cookieService.deleteAll('../');
      localStorage.removeItem('affiliateUrl');
      sessionStorage.removeItem('currencyId');
      sessionStorage.removeItem('storeLogo');
      sessionStorage.removeItem('storeName');
      sessionStorage.removeItem('currencyName');
      sessionStorage.removeItem('searchedValue');
      this.router.navigate(['/home']);
      this.profileDw = false;
      this.loginBtnUp = true;
      window.location.reload();
    } else {
      this.snack.open(data.msg, 'Ok', { duration: 2000 });
    }
  }
  onErrorLogout(data) {}
  search(e) {
    sessionStorage.setItem('searchedValue', e);
    this.searchedKey = sessionStorage.getItem('searchedValue');
    this.handleSearch();
  }

  handleSearch() {
    this.searchFlag = true;
    window.scroll(0, 0);
    let body;
    debugger;
    this.spinner.show();
    this.searched = '';
    // this.searchedKey = e;

    if (this.searchedKey === '') {
      this.spinner.hide();
      this.snack.open('Enter something to search', 'Ok', { duration: 2000 });
    } else if (this.logedCustomerId !== null) {
      body = {
        product_type: this.searchedKey,
        customer_id: this.logedCustomerId,
        tenant_id: this.tenantId,
        session_id: this.logedSeeionId,
      };
    } else if (this.logedCustomerId == null) {
      body = {
        product_type: this.searchedKey,
        tenant_id: this.tenantId,
        session_id: this.guestSessionId,
      };
    }
    body = this.common.withoutDataStoreValidation(body);
    // else {
    //   debugger
    //   this.logedCustomerId = sessionStorage.getItem('customerId');
    //    body = { "product_type": this.searchedKey, "customer_id": this.logedCustomerId, "currency_id": 4,  "tenant_id":3 };

    const success = this.searchSuccess.bind(this);
    const error = this.onErrorSearch.bind(this);
    this.common.http.post('search', body, success, error);
    // }
  }
  searchSuccess(data): any {
    console.log(data.data);
    if (data.res_status === true) {
      this.spinner.hide();
      this.searched = JSON.stringify(data.data);
      const encodedSearch = encodeURI(this.searched);
      this.common.search(data.data);
      //this.router.navigate(['/search', encodedSearch]);
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/search', encodedSearch]);
      // });
    } else {
      const falseData = [];
      this.common.search(falseData);
      this.spinner.hide();
      this.snack.open(data.msg, 'Ok', { duration: 2000 });
      this.searchedKey = '';
      // Swal.fire({
      //   icon: 'error',
      //   text: data.msg
      // });
      // this.router.navigate(['/home']);
    }
  }
  onErrorSearch(data) {}
  asdfgg() {
    console.log('true..');
    this.toggleflag2 = true;
    this.toggleflag = false;
  }
  // clickkk(){
  //     console.log('false..');
  //   this.toggleflag = true;
  //   this.toggleflag2 = false;

  // }
  // onclicked(e){
  //   debugger
  //   console.log('onclicked...e',e);
  // }
  togEvent(ef) {
    const togVar = ef.target.checked;
    this.common.togleClicked(togVar);
    console.log('onclicked...ee', togVar);
  }

  offclicked(status) {
    debugger;
    console.log('offclicked...', status);
    this.botView = status === 'bot' ? true : false;
    this.common.togleClicked(this.botView);
    console.log('bottt...', this.botView);
    this.router.navigate(['/home']);
  }
  // asd(){
  //   console.log('false..');
  //   this.toggleflag = false;

  // }
  // onchange(event){
  //   // if(event.checked == true){
  //   //   this.toggleflag = true;
  //   // }
  //   // else{
  //     this.toggleflag = true;
  //   // }
  // console.log('ev',event);
  // }
  //  onchange(event){

  //     this.lastName = sessionStorage.getItem('lastname');
  //     this.frstName = sessionStorage.getItem('firstname');

  //   console.log('change');
  //   window.location.reload();

  //   }

  changed() {
    this.value1 = this.value;
  }
  sociallogin(): any {
    const infoo = this.utils.getUserInfo();
    const fName = infoo.firstName;
    const lName = infoo.lastName;
    const fullName = infoo.firstName + infoo.lastName;
    const useEmail = infoo.email;

    const body = {
      login_type: 'social',
      firstname: fName,
      lastname: lName,
      username: useEmail,
      password: '',
      cookie: 1,
      telephone: '',
    };
    const success = this.socialloginSuccess.bind(this);
    console.log('bforbody', body);
    const error = this.socialloginerror.bind(this);
    this.common.http.post('customerlogin', body, success, error);
  }
  socialloginSuccess(data) {
    console.log(data);
    console.log('botcoomsucess');

    // const usersocialemaild = data['data'].user_name;
    //   sessionStorage.setItem('user_name', usersocialemaild);
    //   const socialsessionid = data['data'].session_id;
    //   sessionStorage.setItem('session_id', socialsessionid);

    const userdata = data['data'].customer_name;
    sessionStorage.setItem('userdata', userdata);

    const usersessionId = data['data'].session_id;
    sessionStorage.setItem('sessionId', usersessionId);
    //debugger
    const userCustomerId = data['data'].customer_id;
    sessionStorage.setItem('customerId', userCustomerId);

    const useremaild = data['data'].user_name;
    sessionStorage.setItem('userEmail', useremaild);

    const firstname = data['data'].firstname;
    sessionStorage.setItem('firstname', firstname);

    const lastname = data['data'].lastname;
    sessionStorage.setItem('lastname', lastname);

    const mobileno = data['data'].telephone;
    sessionStorage.setItem('telephone', mobileno);

    this.router.navigate(['/home']);
  }
  socialloginerror(data) {
    console.log(data);
    console.log('botcoomerror');
  }

  // Store List

  viewSettings(): void {
    let settings_store;
    // if (this.currentURL.includes('USID')){
    //    settings_store = {
    //     "setting_op_type": "view_store",
    //     "store_id": this.storeId
    //    }
    // }
    // else{
    //       {"limit":"All",
    // "offset":"0",
    // "org_name":"Arkatiss"}
    // const samUrl = 'https://shop.veterneo.com/login';
    // const samUrl = 'https://botcomm.arkatiss.com/veterneo/#/home';

    // const samUrl = window.location.href;

    // const url = samUrl.split('.')[1];
    const url = sessionStorage.getItem('orgName');
    settings_store = {
      org_name: url,
      limit: 'All',
      offset: 0,
      tenant_id: this.tenantId,
    };

    //}

    const success = this.onSuccessStoreList.bind(this);
    const error = this.onErrorStoreList.bind(this);
    this.common.http.post('storelistui', settings_store, success, error);
  }
  onSuccessStoreList(data): any {
    if (data?.res_status === true) {
      this.storeList = data.data;
      sessionStorage.setItem('defaultStore', this.storeList[0].default_store);
    } else {
      this.storeList = [];
    }

    console.log(this.storeList);
  }
  onErrorStoreList(error): any {
    console.log(error);
  }

  select(sId: string, data: any) {
    sessionStorage.setItem('StoreId', sId);
    this.currencyList();
    this.common.AClicked(sId);
    this.router.navigate(['/home'], { queryParams: { USID: sId } });
    this.spinner.show();

    // sessionStorage.removeItem('currencyName');
    let settings_store: any;
    let storeLogo;
    const storeName = data.name;
    sessionStorage.setItem('storeName', storeName);
    this.storeName = sessionStorage.getItem('storeName');

    const storelogo = data.image;
    // storeLogo = storelogo
    if (storelogo !== '') {
      const image = JSON.parse(storelogo);
      if ('file_data' in image) {
        const imgArr = image.file_data;
        imgArr.map((ele) => {
          if (ele.storage_type === 'db') {
            storeLogo = 'data:image/png;base64,' + ele.media_data;
          } else {
            storeLogo = ele.media_data;
          }
        });
      } else {
        storeLogo = image.data;
      }
      sessionStorage.setItem('storeLogo', storeLogo);
    } else {
      sessionStorage.setItem('storeLogo', storelogo);
    }
    this.logo = sessionStorage.getItem('storeLogo');

    if (sId === 'all') {
      this.spinner.hide();
      // sessionStorage.setItem('currencyName', this.defaultCurrencyName)
      this.currencyName = sessionStorage.getItem('currencyName');
    } else {
      this.spinner.hide();

      if (this.storeId === null) {
        settings_store = {
          setting_op_type: 'view',
          store_id: sId,
        };
      } else {
        settings_store = {
          setting_op_type: 'view',
          store_id: this.storeId,
        };
      }
      const success = this.onSuccessStoreView.bind(this);
      const error = this.onErrorStoreView.bind(this);
      this.common.http.post('setting', settings_store, success, error);
    }
    this.spinner.hide();
  }
  onSuccessStoreView(res) {
    // this.spinner.hide()
    this.spinner.hide();
    console.log('view response', res);
    console.log('before select logo', this.logo);

    this.common.viewStore('data');
    this.logo = sessionStorage.getItem('storeLogo');
    console.log('logoo', this.logo);
    this.storeName = sessionStorage.getItem('storeName');
    console.log('currency name', res.data[2].local.currency);
    if (
      res.data[2].local.currency !== '' &&
      res.data[2].local.currency !== null &&
      res.data[2].local.currency !== 'undefined'
    ) {
      sessionStorage.setItem('currencyName', res.data[2].local.code);
      this.currencyName = sessionStorage.getItem('currencyName');
    } else {
      sessionStorage.setItem('currencyName', this.defaultCurrencyName);
      this.currencyName = sessionStorage.getItem('currencyName');
    }
  }
  onErrorStoreView() {}

  // Currency drop List
  currencyList(): void {
    const body = { tenant_id: this.tenantId };
    const success = this.onSuccessCurrencyList.bind(this);
    const error = this.onErrorCurrencyList.bind(this);
    this.common.http.post('currency', body, success, error);
  }
  onSuccessCurrencyList(data): any {
    this.currencyDropList = data.data;
    console.log(this.currencyDropList);
    this.currencyDropList.map((element) => {
      if (element.default_currency === 1 && this.currencyName === null) {
        sessionStorage.setItem('currencyName', element.code);
        this.currencyName = sessionStorage.getItem('currencyName');
        this.defaultCurrencyName = element.code;
      }
    });
  }
  onErrorCurrencyList(error): any {
    console.log(error);
  }
  onSelectCurrency(data) {
    console.log(data);
    sessionStorage.setItem('currencyName', data.code);
    sessionStorage.setItem('currencyId', data.currency_id);
    this.common.currencyClicked(data.currency_id);
    this.currencyName = sessionStorage.getItem('currencyName');
  }
  handlegiftCard() {
    this.router.navigate(['/MyVocherDetails']);
  }
  cart() {
    this.dropFlag = false;
    this.common.cartClicked(this.dropFlag);
    this.router.navigate(['/cart']);
  }
  wishList() {
    console.log('wishlisttttt');
    if (this.logedSeeionId == null) {
      this.router.navigate(['/auth/login']);
    } else {
      this.router.navigate(['/wishlist']);
      // this.router.navigate(['/wishlist'], { relativeTo: this.Activeroute })
    }
  }
  //   getStoreDetails(){

  //     console.log('storeIdlanding.......',this.storeId);

  //     debugger

  //     const settings_store = {
  //       store_id:this.storeId

  //     }
  //     const success = this.onSuccessStoreDetails.bind(this);
  //     const error = this.onErrorStoreDetails.bind(this);
  //     this.common.http.post('StoreAddress', settings_store, success, error);

  //   }
  //   onSuccessStoreDetails(data){

  //     debugger
  //     console.log('storeDetailsucess......',data);
  //     this.storeDetailsArray = data.data;
  // debugger

  //   }
  //   onErrorStoreDetails(data){
  //    debugger
  //     console.log('storeerror',data);
  //   }

  onKey(Event) {
    console.log('layout3', Event);
  }
  loginCknSplash() {
    //  this.clearCookies();
    this.router.navigate(['/auth/login']);
  }
  clearCookies() {
    this.cookieService.deleteAll('../');
  }
  storeSignUp() {
    this.dialog.open(this.invite, { position: { left: '30%' }, width: '50%' });
  }
  submit() {
    const formVal = this.createform.value;
    console.log(formVal);
    // const botUrl = sessionStorage.getItem('botUrl')
    // const splttdUrl = botUrl.split('#')[0]
    // console.log('tenant name url',splttdUrl)
    // const urlObj=   splttdUrl.split('/')[3]
    // console.log(urlObj)
    if (!formVal.name || !formVal.mail || !formVal.num) {
      this.snack.open('please fill the required fields', 'Ok', {
        duration: 2000,
      });
    } else {
      let body = {
        store_enquiry_op_type: 'insert',
        name: formVal.name,
        email: formVal.mail,
        phone_number: formVal.num,
        short_note: formVal.note,
        user_details: this.orgName.toLowerCase(),
        tenant_name: this.orgName.toLowerCase(),
      };
      console.log(body);
      const success = this.onSuccessStoreSignUp.bind(this);
      this.common.http.post('inquiry', body, success);
    }
  }
  onSuccessStoreSignUp(response) {
    console.log(response);
    if (response.res_status === true) {
      this.snack.open(
        'Your enquiry has been submitted, Onboarding team will get in touch with you further details.',
        'Ok',
        { duration: 10000 }
      );
      this.categoryList = [];

      this.dialog.closeAll();
    } else {
      this.snack.open(response.msg, 'Ok', { duration: 2000 });
    }
  }
  categoriesFlag = false;
  viewCategory(event: MouseEvent) {
    // const categoryDetails: any = document.getElementById('categoryDetails');
    // categoryDetails.style.display = 'block';
    this.categoriesFlag = true;
    console.log('this.categoriesFlag', this.categoriesFlag);
    // this.dialog.open(this.CategoryDetails, {
    //   position: { top: '2%' },
    //   width: '100%',
    //   height: '95%',
    // });
    this.categoryList = [];
    this.categoryList = this.menuObj.menuData[0].categoryDetails;
    this.parentId = this.menuObj.menuData[0].parentId;
  }

  hideCard(event: MouseEvent) {
    const categoryDetails: any = document.getElementById('categoryDetails');
    console.log('categoryDetails', categoryDetails);
    if (categoryDetails) {
      console.log('categoryDetails1', categoryDetails);
      const isMouseInsidePopup = categoryDetails.contains(event.relatedTarget);
      // Hide the popup only if the mouse is not inside it
      if (!isMouseInsidePopup) {
        categoryDetails.style.display = 'none';
        this.categoriesFlag = false;
      }
    }
  }
  categoryView(event) {
    console.log('event', event);
    this.categoryList = [];
    // this.categoryList = this.menuObj.menuData[0].categoryDetails;
    // this.parentId = this.menuObj.menuData[0].parentId;
    // this.goToProducts(event.parentId, event.parentName);
    this.goToProducts(event.parentId, event.parentName);
  }
  leave() {
    this.dialog.closeAll();
  }
  // getGuestSession(){
  //   debugger

  //   const body = {

  //         };
  //   const success = this.sgetGuestSessionSuccess.bind(this);

  //   const error = this.getGuestSessionerror.bind(this);
  //   this.common.http.post('Session', body, success, error);

  // }
  // sgetGuestSessionSuccess(data){
  //   console.log(data);
  //   this.GuestSessionId = data.session_id;
  // }
  // getGuestSessionerror(data){
  //   console.log(data);
  // }

  /**
   * Get tenantId from organization name in url
   * @author Devi
   * @version 1.0
   */

  getOrgId() {
    let url;
    // const samUrl = 'https://botcomm.arkatiss.com/Arkatiss/#/home';
    // const samUrl = 'https://botcomm.arkatiss.com/veterneo/#/home';
    // const samUrl = 'https://shop.veterneo.com/login';
    // const samUrl = window.location.href;
    url = sessionStorage.getItem('orgName');
    // url = samUrl.split('.')[1];
    console.log('urllll', url);
    let body = { org_name: url };

    this.ms.getTenantId(body).subscribe((data) => {
      console.log('url response', data);
      if (data.res_status === true) {
        this.getCategories(data.tenant_id);
        sessionStorage.setItem('tenantId', data.tenant_id);
        localStorage.setItem('tenantCompanyName', data.company);
        this.tenantId = sessionStorage.getItem('tenantId');
        this.viewSettings();
        this.currencyList();
      }
    });
  }
  /**
   * Get Device Info
   * @author Devi
   * @version 1.0
   */
  epicFunction() {
    console.log('hello `Home` component');
    const deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log('deviceInfo', deviceInfo);
    console.log('isMobile', isMobile); // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log('isTablet', isTablet); // returns if the device us a tablet (iPad etc)
    console.log('isDesktopDevice', isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  /**
   * Generate Random Id
   * @author Devi
   * @version 1.0
   */
  genarateId() {
    const deviceId = localStorage.getItem('UUID');
    if (deviceId === null || deviceId === undefined) {
      localStorage.setItem('UUID', uuid.v4());
      this.deviceUID = localStorage.getItem('UUID');
    }
    console.log('UUID', deviceId);
  }
}
